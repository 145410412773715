<!-- PROGRESS BAR -->
<fuse-progress-bar></fuse-progress-bar>
<!-- / PROGRESS BAR -->

<div class="outlet-print">
    <router-outlet name="subpiprint"></router-outlet>
    <router-outlet name="subkontrakprint"></router-outlet>
    <router-outlet name="slipuangtambahan"></router-outlet>
    <router-outlet name="slipuangtambahannontrado"></router-outlet>
    <router-outlet name="laporanvoucher"></router-outlet>
    <router-outlet name="rsujcetak"></router-outlet>
    <router-outlet name="rsujtradocetak"></router-outlet>
    <router-outlet name="sujcetak"></router-outlet>
    <router-outlet name="sujcetaktrado"></router-outlet>
    <router-outlet name="sugcetak"></router-outlet>
    <router-outlet name="sugtradocetak"></router-outlet>
    <router-outlet name="kontrakcetak"></router-outlet>
    <router-outlet name="dosharing"></router-outlet>
    <router-outlet name="pemakaianvoucherbbm"></router-outlet>
    <router-outlet name="pengajuanpembayaranekspedisi"></router-outlet>
    <router-outlet name="pengajuanreturpembayaranekspedisi"></router-outlet>
    <router-outlet name="bpujdatanontrado"></router-outlet>
    <router-outlet name="bpujdatatrado"></router-outlet>
    <router-outlet></router-outlet>
</div>
<!-- VERTICAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-1'">

    <vertical-layout-1>
        <!-- <router-outlet name="subkontrakprint"></router-outlet> -->
    </vertical-layout-1>
</ng-container>
<!-- / VERTICAL LAYOUT 1 -->

<!-- VERTICAL LAYOUT 2 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-2'">
    <vertical-layout-2>
    </vertical-layout-2>
</ng-container>
<!-- / VERTICAL LAYOUT 2 -->

<!-- VERTICAL LAYOUT 3 -->
<ng-container *ngIf="fuseConfig.layout.style === 'vertical-layout-3'">
    <vertical-layout-3>
    </vertical-layout-3>
</ng-container>
<!-- / VERTICAL LAYOUT 3 -->

<!-- HORIZONTAL LAYOUT 1 -->
<ng-container *ngIf="fuseConfig.layout.style === 'horizontal-layout-1'">
    <horizontal-layout-1>
    </horizontal-layout-1>
</ng-container>
<!-- / HORIZONTAL LAYOUT 1 -->

<!-- THEME OPTIONS PANEL -->
<!-- <button mat-icon-button class="warn mat-elevation-z2 theme-options-button"
    (click)="toggleSidebarOpen('themeOptionsPanel')">
    <mat-icon>settings</mat-icon>
</button> -->

<fuse-sidebar name="themeOptionsPanel" class="theme-options-sidebar" position="right" [invisibleOverlay]="true">
    <fuse-theme-options></fuse-theme-options>
</fuse-sidebar>
<!-- / THEME OPTIONS PANEL -->


<!-- <router-outlet name="print"></router-outlet> -->