export class AuthViewModel
{
    Username : string = "rezanaipospos";
    Password : string = "perang96";

    constructor(AuthViewModel?)
    {
        AuthViewModel = AuthViewModel || {} ; 
        this.Username = "rezanaipospos";
        this.Password ="perang96";
    }
}