import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { VersionInfoService } from 'app/service/pengaturan/version-info/version-info.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'quick-panel',
    templateUrl: './quick-panel.component.html',
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent {
    version_info_list: any[];
    last_version: string;
    date: Date;

    filter: any;

    constructor(
        private _VersionInfoService: VersionInfoService,
    ) {
        // Set the defaults
        this.date = new Date();
    }

    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        //var token = JSON.parse(localStorage.getItem("currentUser")).token;
        //if (token){
         //   this.loadInfo();
        //}
        
    }

    loadInfo() {
        this.filter = { version: null, info: null, priority: null };

        this._VersionInfoService.getVersionInfo(this.filter, '', 'desc', 0, 10).subscribe((resp) => {
            this.version_info_list = resp.result;
        });
    }

    loadLastVersion() {
        this._VersionInfoService.getLastVersion().subscribe((resp) => {
            this.last_version = resp;
        });
    }
}
