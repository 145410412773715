import { environment } from 'environments/environment';
import packageInfo from '../../package.json';

// Local Testing
export class AppSettings {
    baseUrlroot: string = environment.baseUrlroot;
    baseUrl: string = environment.baseUrl;
    version: string = packageInfo.version;
    appname: string = 'OPR Logistics';
    // baseUrlRFID: string = 'http://192.168.100.111:5000';
    baseUrlRFID: string = 'http://localhost:5000';
    status: string = environment.statusApp;
}
