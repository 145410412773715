import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AlertService } from 'app/service/common/alert/alert.service';
import { RFIDService } from 'app/service/common/rfid/rfid.service';
import { PublicService } from 'app/service/public.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';

export class ParamUpdateStatusWhenCheckStatus {
  status_voucher:string 
  statusInjectProcess:string
  messageNotif:string
  messageNotifFailtoUpdate:string
  statusInjectProcessErr:string
  messageNotifErr:string
}
@Component({
  selector: 'app-inject-voucher-modal',
  templateUrl: './inject-voucher-modal.component.html',
  styleUrls: ['./inject-voucher-modal.component.scss']
})
export class InjectVoucherModalComponent implements OnInit, OnDestroy {
  statusInjectProcess:string = ""
  interval;
  timeLeft: number = 5;
  statusInject:boolean = false;
  title = ""
  checkStatusVoucherOnManchine:boolean = false
  textinButton =""
  type = "" //value add or retur
  public loadingSubject$ = new BehaviorSubject(false);
  public checkRfid: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public param: any,
    public dialogRef: MatDialogRef<InjectVoucherModalComponent>,
    private _matSnackBar: MatSnackBar,
    private _publicService: PublicService,
    public _RFIDService: RFIDService,
    private spinner: NgxSpinnerService,
    private _alertService: AlertService
  ) {
    this.statusInject = false;
    this.title = this.param.title;
    this.textinButton = this.param.textinButton || "Inject"
    this.type = this.param.type || "add"
  //FORMAT 
  //    {
  //     data: {dataInjectV:dataInjectV,dataUpdateStatusVocuher:dataUpdateStatusVocuher},
  //     message: "",
  //     type_trx: "SUJ"
  //    };
  // PARAM.data. dataInjectV = data untuk diinject ke Lokal mesin (_RFIDService.simpan) 
  // PARAM.data. dataUpdateStatusVocuher = data untuk dikirim ke API untuk update status Transaksi Voucher() (_RFIDService.simpan)
  // param.type_trx = type transaksi isinya SUJ (Slip Uang Jalan Non-Trado), SUJT (Slipt Uang Jalan Trado),
  //Status RFID (Belum terkirim ke mesin, terkirim ke mesin, berhasil inject voucher, pengisian voucher berhasil, Retur, Berhasil Retur  Dan Kartu Telah Dikosongkan)
   }

  get loading$() {
    return this.loadingSubject$.asObservable();
  }

  ngOnInit() {
    this.loadingSubject$.next(true);
    this._publicService.checkCabangRFID(this._publicService.getLocalCabang('comloc_id')).then((res) => {
      this.checkRfid = res
      this.loadingSubject$.next(false);
    }).catch((err) => this._alertService.Global_Alert('error', 'Error', err.err ? JSON.stringify(err.err) : err))
  }

  ngOnDestroy(): void {
    
  }


  get keterangan(){
    return this.param.data.dataInjectV
  }
  
  onCancel(tipe){
    if (tipe == "Batal" && this.type == "retur") {
      //HAPUS VOUCHER TANPA KARTU HANYA UPDATE STATUS MENJADI RETUR SAJA
      this.onClickBtnBatalWhenDltVoucher()
    } else {
      this.dialogRef.close(this.param)
    }
  }

  Inject() {
    this.spinner.show();

    
    //UPDATE STATUS VOUCHER UNTUK RFID
    this._RFIDService.simpan(this.param.data.dataInjectV).subscribe(respSimRFID => {
      
      if (respSimRFID.status == 200) {
        this.statusInjectProcess = "Voucher Berhasil diinject kemesin lokal...."
            //UPDATE STATUS VOUCHER
        this._publicService.updateStatusVocuherRFID(this.param.data.dataUpdateStatusVocuher).subscribe(respUpdStat => {
            if (respUpdStat.status) {
              if (this.param.data.dataUpdateStatusVocuher[0].status == "Retur") {
                this.statusInjectProcess = "Voucher Berhasil diinject kemesin lokal dengan status Retur dan Status Voucher berhasil diupdate...."
                this._matSnackBar.open("Voucher Berhasil di Retur dan Status Voucher berhasil diupdate!","Ok",{
                  verticalPosition:'top',
                  duration: 5000
              })
              } else {
                this.statusInjectProcess = "Voucher Berhasil diinject kemesin lokal dan Status Voucher berhasil diupdate...."
                this._matSnackBar.open("Voucher Berhasil Dikirim Ke Mesin Scanner dan Status Voucher Berhasil Diupdate!","Ok",{
                  verticalPosition:'top',
                  duration: 5000
              })
              }
               
              
            } else {
              this.statusInjectProcess = "Voucher Berhasil diinject kemesin lokal dan Status Voucher Gagal diupdate...."
                this._matSnackBar.open("Voucher Berhasil diinject dan Status Voucher Gagal diupdate, Silahkan hubungi Administrator'","Error",{
                    verticalPosition:'top',
                    duration: 6000
                })
            }

            // this.statusInject = true;
            this.checkStatusVoucherOnManchine = true;
            this.statusInjectProcess = "Silahkan Letakan kartu ke scanner!"
            this.checkStatusVoucher()
                this.interval = setInterval(() => {
                  if (this.checkStatusVoucherOnManchine) {
                    this.checkStatusVoucher()
                  } else {
                    clearInterval(this.interval)
                   
                  }
              },5000)
        },
        //ERROR UPDATE STATUS VOUCHER
        error => {
          console.error(error);
          this._matSnackBar.open('Oops! Gagal Update Status Voucher, error: ' + error.status
          +', Pesan ' + error.statusText + ', Silahkan hubungi Administrator', "Ok", {
              verticalPosition: 'top',
              duration: 5000
          });
          this.spinner.hide();
        });
      }
    },
    //ERROR SIMPAN RFID DILOKAL
    error => {
      console.error(error);
      if (error.status == 401) {
        this._RFIDService.onLoginRFID();
        this._matSnackBar.open('Oops! Gagal Simpan Voucher ke Mesin Lokal, Hak Akses Expired, Silahkan Inject Ulang Voucher. error: ' + error.status
        +', Pesan ' + error.statusText + '', "Ok", {
          verticalPosition: 'top',
          duration: 5000
        });
      } else if(error.status == 400) {
        this._matSnackBar.open('Oops! Gagal Simpan Voucher ke Mesin Lokal, Pesan: ' + error.error.message , "Ok", {
          verticalPosition: 'top',
          duration: 5000
        });
      } else {
        this._matSnackBar.open('Oops! Gagal Simpan Voucher ke Mesin Lokal, error: ' + error.status
        +', Pesan ' + error.statusText + ', Silahkan hubungi Administrator', "Ok", {
          verticalPosition: 'top',
          duration: 5000
        });
      }
      this.spinner.hide();
    });
  }

  checkStatusVoucher() {
    
      this._RFIDService.CheckStatusVoucher(this.param.data.dataInjectV).subscribe( respCheckStatusVocuher => {
          if (respCheckStatusVocuher.status_voucher == "Berhasil Inject Voucher") {
            var param = new ParamUpdateStatusWhenCheckStatus()
            param.status_voucher =  respCheckStatusVocuher.status_voucher
            param.statusInjectProcess = "Voucher Berhasil diisi ke kartu RFID."
            param.messageNotif = "Voucher Berhasil diisi ke kartu RFID!"
            param.messageNotifFailtoUpdate = "Voucher Berhasil diisi ke kartu RFID dan Status Voucher Gagal diupdate..."
            param.statusInjectProcessErr = "Voucher Berhasil diisi ke kartu RFID dan Status Voucher Gagal diupdate"
            param.messageNotifErr = "Voucher Berhasil diisi ke kartu RFID dan Status Voucher Gagal diupdate"
            this.updateStatusVoucherWhenCheckStatus(param)
          } else if(respCheckStatusVocuher.status_voucher == "Berhasil Retur Dan Kartu Telah Dikosongkan"){
            var param = new ParamUpdateStatusWhenCheckStatus()
            param.status_voucher =  respCheckStatusVocuher.status_voucher
            param.statusInjectProcess = respCheckStatusVocuher.status_voucher
            param.messageNotif = respCheckStatusVocuher.status_voucher
            param.messageNotifFailtoUpdate = respCheckStatusVocuher.status_voucher + " dan Status Voucher Gagal diupdate"
            param.statusInjectProcessErr = respCheckStatusVocuher.status_voucher + " dan Status Voucher Gagal diupdate"
            param.messageNotifErr = respCheckStatusVocuher.status_voucher + " dan Status Voucher Gagal diupdate"
            this.updateStatusVoucherWhenCheckStatus(param)
          } 
      },
      error => {
        console.error(error)
        this.checkStatusVoucherOnManchine = false;
        this.statusInjectProcess = "Gagal Cek Status Voucher ke Mesin Lokal...."
        this._matSnackBar.open('Oops! Gagal Cek Status Voucher ke Mesin Lokal, error: ' + error.status
          +', Pesan ' + error.error.message ? null : error.statusText  + ', Silahkan hubungi Administrator', "Ok", {
            verticalPosition: 'top',
            duration: 5000
        });
        this.spinner.hide();
      })
  }

  updateStatusVoucherWhenCheckStatus(param:ParamUpdateStatusWhenCheckStatus){
    var UpStatusVoucher=[]
    for (let index = 0; index <  this.param.data.dataUpdateStatusVocuher.length; index++) {
      const element = this.param.data.dataUpdateStatusVocuher[index];
      element.status = param.status_voucher
      UpStatusVoucher.push(element)
    }
    this._publicService.updateStatusVocuherRFID(UpStatusVoucher).subscribe(respUpdStat => { 
      this.statusInjectProcess = param.statusInjectProcess
      this.checkStatusVoucherOnManchine = false;
        if (respUpdStat.status) {
          this._matSnackBar.open(param.messageNotif,"Ok",{
            verticalPosition:'top',
            duration: 5000,
          })
          this.onCancel(this.statusInjectProcess)
        } else {
          this._matSnackBar.open(param.messageNotif,"Ok",{
            verticalPosition:'top',
            duration: 5000,
          })
        } 
        this.spinner.hide();
    }, 
    errUpdateStatus => {
      console.error(errUpdateStatus)
      this.checkStatusVoucherOnManchine = false;
      this.statusInjectProcess = param.statusInjectProcessErr
      this._matSnackBar.open(param.messageNotifErr +', error: ' + errUpdateStatus.status
        +', Pesan ' + errUpdateStatus.error.message ? null : errUpdateStatus.statusText  + ', Silahkan hubungi Administrator', "Ok", {
          verticalPosition: 'top',
          duration: 5000
      });
      this.spinner.hide();
    })
  }
  
  onClickBtnBatalWhenDltVoucher(){
     this.spinner.show();

    
    //UPDATE STATUS VOUCHER UNTUK RFID
    this._RFIDService.simpan(this.param.data.dataInjectV).subscribe(respSimRFID => {
      
      if (respSimRFID.status == 200) {
        this.statusInjectProcess = "Status Voucher Berhasil diupdate kemesin lokal...."
            //UPDATE STATUS VOUCHER
        this._publicService.updateStatusVocuherRFID(this.param.data.dataUpdateStatusVocuher).subscribe(respUpdStat => {
            if (respUpdStat.status) {
              if (this.param.data.dataUpdateStatusVocuher[0].status == "Retur") {
                this.statusInjectProcess = "Status Voucher Berhasil diupdat kemesin lokal dengan status Retur dan Status Voucher berhasil diupdate...."
                this._matSnackBar.open("Voucher Berhasil di Retur dan Status Voucher berhasil diupdate!","Ok",{
                  verticalPosition:'top',
                  duration: 5000
              })
            } else {
               this.statusInjectProcess = "Voucher Berhasil diinject kemesin lokal dan Status Voucher berhasil diupdate...."
                this._matSnackBar.open("Voucher Berhasil diinject dan Status Voucher berhasil diupdate!","Ok",{
                  verticalPosition:'top',
                  duration: 5000
              })
            }
            this.onCancel('Berhasil Retur')
            this.spinner.hide();
          }
        },
        //ERROR UPDATE STATUS VOUCHER
        error => {
          console.error(error);
          this._matSnackBar.open('Oops! Gagal Update Status Voucher, error: ' + error.status
          +', Pesan ' + error.statusText + ', Silahkan hubungi Administrator', "Ok", {
              verticalPosition: 'top',
              duration: 5000
          });
          this.spinner.hide();
        })
      }
    }, error=>{
      console.error(error);
      if (error.status == 401) {
        this._RFIDService.onLoginRFID();
        this._matSnackBar.open('Oops! Gagal Update Status Voucher ke Mesin Lokal, Hak Akses Expired, Silahkan Inject Ulang Voucher. error: ' + error.status
        +', Pesan ' + error.statusText + '', "Ok", {
          verticalPosition: 'top',
          duration: 5000
        });
      } else if(error.status == 400) {
        this._matSnackBar.open('Oops! Gagal  Update Status Voucher ke Mesin Lokal, Pesan: ' + error.error.message , "Ok", {
          verticalPosition: 'top',
          duration: 5000
        });
      } else {
        this._matSnackBar.open('Oops! Gagal  Update Status Voucher ke Mesin Lokal, error: ' + error.status
        +', Pesan ' + error.statusText + ', Silahkan hubungi Administrator', "Ok", {
          verticalPosition: 'top',
          duration: 5000
        });
      }
      this.spinner.hide();
    })
  }
}
