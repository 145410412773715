import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginViewModel } from 'app/view-model/login-view-model';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SignUpViewModel } from 'app/view-model/sign-up-view-model';
import { Router } from '@angular/router';
import { AppSettingsService } from 'app/app.settings.service';
import { AppSettings } from 'app/app.settings';
import { environment } from 'environments/environment.prod';
import { PublicService } from './public.service';
// import { DocumentChangeAction } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private httpClient: HttpClient;
  settings: AppSettings;
  baseUrl: string;
  constructor(
    private httpBackend: HttpBackend,
    private jwtHelperService: JwtHelperService,
    private router: Router,
    private appSettingsService: AppSettingsService,
    private _PublicService: PublicService, ) {

    this.appSettingsService.getSettings()
      .subscribe(settings =>
        this.settings = settings,
        () => null,
        () => {
          this.baseUrl = this.settings.baseUrlroot;
        });
  }
  currentUserName: string = null;
  currentCustomerId: string = null;
  currentRole: string = null;
  customers: any;

  public Login(loginViewModel: LoginViewModel): Observable<any> {
    this.httpClient = new HttpClient(this.httpBackend);
    return this.httpClient.post<any>(this.baseUrl + '/authenticate', (loginViewModel), { responseType: 'json', observe: 'response' })
      .pipe(map(response => {
        if (response) {
          this.currentUserName = response.body.userName;
          this.currentCustomerId = response.body.customer_Id;
          this.currentRole = response.body.role;
          localStorage.currentUser = JSON.stringify(response.body);
          localStorage.XSRFRequestToken = response.headers.get('XSRF-REQUEST-TOKEN');
          this.detectFirebaseToken();
        }
        return response.body;
      }));
  }

  public Register(signUpViewModel: SignUpViewModel): Observable<any> {

    this.httpClient = new HttpClient(this.httpBackend);
    return this.httpClient.post<any>('/register', signUpViewModel, { responseType: 'json', observe: 'response' })
      .pipe(map(response => {
        if (response) {
          this.currentUserName = response.body.userName;
          localStorage.currentUser = JSON.stringify(response.body);
          localStorage.XSRFRequestToken = response.headers.get('XSRF-REQUEST-TOKEN');

          return response.body;
        }
      }));
  }
  public isAuthenticated(): boolean {
    const token = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser')).token : null;
    if (this.jwtHelperService.isTokenExpired()) {
      return false; // token is not valid
    }
    else {
      return true; // token is valid
    }
  }
  public Logout(): void {
    localStorage.clear();
    this.currentUserName = null;
    this.currentCustomerId = null;
    this.currentRole = null;
    this.router.navigate(['home/login']);
  }
  public detectIfAlreadyLoggedIn(): void {
    if(!localStorage.getItem('cabang')) {
      this.Logout();
    }
    else if (this.jwtHelperService.isTokenExpired() === false) {
      const currentUser = JSON.parse(localStorage.currentUser);
      // var menu = JSON.parse(localStorage.menu);
      // var cabang = JSON.parse(localStorage.cabang);
      // var XSRFRequestToken = JSON.parse(localStorage.XSRFRequestToken);
      this.currentUserName = currentUser.userName;
      this.currentRole = currentUser.role;
      this.currentCustomerId = currentUser.customer_Id;
      this.detectFirebaseToken();
      this.router.navigate(['/dashboard/analytics']);
    }
  }

  public detectFirebaseToken(): void {
    const currentUser = JSON.parse(localStorage.currentUser);
    if (environment.firebaseToken !== currentUser.firebaseToken || environment.firebaseToken === '' || environment.firebaseToken == null) {
      this._PublicService.requestPermission();
    }
  }
}
