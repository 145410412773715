<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="pacman">
    <p style="font-size: 20px; color: white">{{statusInjectProcess}}</p>
</ngx-spinner>
<div class="dialog-content-wrapper"  >
    <div class="header">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">{{ title }}</span>
                <!-- <button mat-icon-button (click)="onCancel('')" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button> -->
                <!-- BUTTON DIHAPUS KARENA YANG DIPAKAI HANYA TOMBOL BATAL DAN INJECT  -->
            </mat-toolbar-row>
        </mat-toolbar>
        <hr>
    </div>
    <div class="content-card">
        <div class="body" style="padding: 10px;text-align: center;">
            <!-- Silahkan tempelkan Kartu RFID kemesin -->
            <table>
              <tr>
                <td>Nomor SUJ</td>
                <td>:</td>
                <td>{{ keterangan.suj_nomor }}</td>
              </tr>
              <tr>
                <td>Nomor Polisi</td>
                <td>:</td>
                <td>{{ keterangan.no_polisi }}</td>
              </tr>
              <tr>
                <td>Nomor RFID</td>
                <td>:</td>
                <td>{{ keterangan.no_kartu }}</td>
              </tr>
            </table>
        </div>
        <div class="footer" style="padding: 10px;">
            <div fxLayout="row end" fxLayoutAlign="end" fxFlex>
                <button   mat-raised-button color="accent" (click)="Inject()" [disabled]="(loading$ | async) || !checkRfid"><span>{{textinButton}}</span></button>
                &nbsp;
                <button class="mat-raised-button" (click)="onCancel('Batal')" aria-label="Close dialog"
                    xLayoutAlign="center right">
                    <span>Batal</span>
                </button>
            </div>
        </div>
    </div>
</div>