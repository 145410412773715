import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { dataApproval } from 'app/view-model/transaksi/approve/approve';
import { ApproveService } from 'app/service/transaksi/approve/approve.service';
import { PublicService } from 'app/service/public.service';
import { NotificationFireStore, PushNotification, Notification } from 'app/view-model/notification/notification';
import { AlertService } from 'app/service/common/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-approve',
    templateUrl: './approve.component.html',
    styleUrls: ['./approve.component.scss']
})
export class ApproveComponent implements OnInit {
    message: string = "Are you sure?"
    title: string = "Tolak"
    confirmButtonText = "SETUJU"
    cancelButtonText = "BATAL"
    reason: string = ""
    status: number = 1 || 2
    type_trx: string = "SUJ" || "SUJTrado" || ""
    id_array: string[] = [];
    firebase_token_email_current: any[] = [];
    firebase_token_email_next: any[] = [];
    notification: NotificationFireStore = new NotificationFireStore;
    varnotification: Notification;
    VarPushNotification: PushNotification;
    nomor: string
    constructor(
        @Inject(MAT_DIALOG_DATA) private param: dataApproval,
        public dialogRef: MatDialogRef<ApproveComponent>,
        private _approveService: ApproveService,
        private _matSnackBar: MatSnackBar,
        private _PublicService: PublicService,
        private _alertService: AlertService,
        private _spinner: NgxSpinnerService
    ) {
        if (param) {
            this.id_array = param.data.id_array;
            this.type_trx = param.type_trx;
            this.status = param.status || this.status;
            if (this.status == 1) {
                this.title = "Apakah anda yakin ingin menyetujui transaksi ini?"
            } else {
                this.title = "Apakah anda yakin ingin menolak transaksi ini?"
            }
        }
    }

    ngOnInit() {
        // console.log(this.param)

    }
    onClose() {
        this.dialogRef.close("data" + this.param)
    }
    addFireStore(docEmail: string, notification: NotificationFireStore, TypeIdTransaction: string) {
        this._PublicService.createNotification(docEmail, notification, TypeIdTransaction)
    }
    removeFireStore(docEmail: string, notification: NotificationFireStore, TypeIdTransaction: string) {
        this._PublicService.deleteNotification(docEmail, notification, TypeIdTransaction)
    }

    AddfirebaseFCMandFireStore(
        firebase_token_email_next: any,
        firebase_token_email_current: any,
        transaksi_id: number,
        type: string,
        link: string,
        body: string,
        title: string,
        status: number) {

        this.firebase_token_email_next = firebase_token_email_next;
        this.firebase_token_email_current = firebase_token_email_current;
        var TypeIdTransaction = type + transaksi_id;
        this.notification.click_action = location.origin + link;
        this.notification.transactionId = transaksi_id;
        this.notification.body = body;
        this.notification.title = title;
        this.notification.type = type;
        this.notification.link = link;

        if (status == 1) {
            this.firebase_token_email_next.forEach(data => {
                //ADD FIRESTORE
                this.addFireStore(data.email, this.notification, TypeIdTransaction);
                //ADD FCM
                this.varnotification = { title: this.notification.title, data: {}, body: this.notification.body, click_action: this.notification.click_action }
                this.VarPushNotification = { notification: this.varnotification, to: data.firebase_token }
                // if (data.firebase_token) {
                //   this._PublicService.PushNotification(this.VarPushNotification).subscribe((result) => { });
                // }
            });
            this.firebase_token_email_current.forEach(data => {
                this.removeFireStore(data.email, this.notification, TypeIdTransaction);
            })
        } else {
            this.firebase_token_email_current.forEach(data => {
                this.removeFireStore(data.email, this.notification, TypeIdTransaction);
            })
        }

    }
    onConfirmClick(status: number) {
        if (this.type_trx == "SUJ") {
            this._approveService.ApproveSUJ(status, this.reason, this._PublicService.getLocalCabang('comloc_id'), this.id_array).subscribe(resp => {
                if (resp) {
                    var message = "";
                    this.AddfirebaseFCMandFireStore(resp.nextuserapprove,
                        resp.currentleveluser,
                        resp.transaksi_id,
                        this.type_trx,
                        "/#/transaksi/armada-berangkat/suj",
                        "Approve Slip Uang Jalan " + this.param.data.suj_nomor,
                        "Pemberitahuan",
                        status);
                    if (status == 1) {
                        message = "Transaksi Berhasil Disetujui!"

                    } else {
                        message = "Transaksi Berhasil Ditolak!"
                    }
                    this._matSnackBar.open(
                        message,
                        "Info",
                        {
                            verticalPosition: "top",
                            duration: 5000,
                        }
                    );
                    setTimeout(() => {
                        this.onClose();
                    }, 500);
                }
            })
        }
        else if (this.type_trx == "SUT") {
          this._spinner.show();
            this._approveService.ApproveSUT(this.status, this.reason, this._PublicService.getLocalCabang('comloc_id'), this.id_array).subscribe(resp => {
                if (resp) {
                    var message = "";
                    this.AddfirebaseFCMandFireStore(resp.nextuserapprove,
                        resp.currentleveluser,
                        resp.transaksi_id,
                        this.type_trx,
                        "/#/transaksi/armada-berangkat/non-trado/slip-uang-tambahan-edit",
                        "Approve Slip Uang Tambahan " + this.param.data.sut_nomor,
                        "Pemberitahuan",
                        status);
                    console.log(status);
                    if (status == 1) {
                        message = "Transaksi Berhasil Disetujui!"
                    } else {
                        message = "Transaksi Berhasil Ditolak!"
                    }
                    this._matSnackBar.open(
                        message,
                        "Info",
                        {
                            verticalPosition: "top",
                            duration: 5000,
                        }
                    );
                    setTimeout(() => {
                        this.onClose();
                    }, 500);
                }
            }, e => {
              this._alertService.Global_Alert('error', 'SUJ Sudah Diretur', e.error.message);
              this._spinner.hide();
            }, () => {
              this._spinner.hide();
            })
        }
        else if (this.type_trx == "SUG") {
            this._approveService.ApproveSUG(status, this.reason, this._PublicService.getLocalCabang('comloc_id'), this.id_array).subscribe(resp => {
                if (resp) {
                    var message = "";
                    this.AddfirebaseFCMandFireStore(resp.nextuserapprove,
                        resp.currentleveluser,
                        resp.transaksi_id,
                        this.type_trx,
                        "/#/transaksi/uang-gantung/non-trado/uang-gantung-data",
                        "Approve Uang Gantung " + this.param.data.sug_nomor,
                        "Pemberitahuan",
                        status);
                    if (status == 1) {
                        message = "Transaksi Berhasil Disetujui!"
                    } else {
                        message = "Transaksi Berhasil Ditolak!"
                    }
                    this._matSnackBar.open(
                        message,
                        "Info",
                        {
                            verticalPosition: "top",
                            duration: 5000,
                        }
                    );
                    setTimeout(() => {
                        this.onClose();
                    }, 500);
                }
            })
        }
        else if (this.type_trx == "SUJTrado") {
            this._approveService.ApproveSUJTrado(status, this.reason, this._PublicService.getLocalCabang('comloc_id'), this.id_array).subscribe(resp => {
                if (resp) {
                    var message = "";
                    this.AddfirebaseFCMandFireStore(resp.nextuserapprove,
                        resp.currentleveluser,
                        resp.transaksi_id,
                        this.type_trx,
                        "/#/transaksi/armada-berangkat/trado/suj/edit",
                        "Approve Slip Uang Jalan Trado " + this.param.data.suj_trado_nomor,
                        "Pemberitahuan",
                        status);
                    if (status == 1) {
                        message = "Transaksi Berhasil Disetujui!"
                    } else {
                        message = "Transaksi Berhasil Ditolak!"
                    }
                    this._matSnackBar.open(
                        message,
                        "Info",
                        {
                            verticalPosition: "top",
                            duration: 5000,
                        }
                    );
                    setTimeout(() => {
                        this.onClose();
                    }, 500);
                }
            })
        }
        else if (this.type_trx == "SUTTrado") {
            this._spinner.show();
            this._approveService.ApproveSUTTrado(status, this.reason, this._PublicService.getLocalCabang('comloc_id'), this.id_array).subscribe(resp => {
                if (resp) {
                    var message = "";
                    this.AddfirebaseFCMandFireStore(resp.nextuserapprove,
                        resp.currentleveluser,
                        resp.transaksi_id,
                        this.type_trx,
                        "/#/transaksi/armada-berangkat/trado/slip-uang-tambahan-edit",
                        "Approve Slip Uang Tambahan Trado " + this.param.data.sut_trado_nomor,
                        "Pemberitahuan",
                        status);
                    if (status == 1) {
                        message = "Transaksi Berhasil Disetujui!"
                    } else {
                        message = "Transaksi Berhasil Ditolak!"
                    }
                    this._matSnackBar.open(
                        message,
                        "Info",
                        {
                            verticalPosition: "top",
                            duration: 5000,
                        }
                    );
                    setTimeout(() => {
                        this.onClose();
                    }, 500);
                }
            }, (e) => {
              this._alertService.Global_Alert("error", "SUJ Sudah Diretur", e.error.message);
              this._spinner.hide();
            }, () => {
              this._spinner.hide();
            })
        }
        else if (this.type_trx == "SUGTrado") {
            this._approveService.ApproveSUGTrado(status, this.reason, this._PublicService.getLocalCabang('comloc_id'), this.id_array).subscribe(resp => {
                if (resp) {
                    var message = "";
                    this.AddfirebaseFCMandFireStore(resp.nextuserapprove,
                        resp.currentleveluser,
                        resp.transaksi_id,
                        this.type_trx,
                        "/#/transaksi/uang-gantung/trado/uang-gantung-trado-data",
                        "Approve Slip Uang Jalan " + this.param.data.sug_trado_nomor,
                        "Pemberitahuan",
                        status);
                    if (status == 1) {
                        message = "Transaksi Berhasil Disetujui!"
                    } else {
                        message = "Transaksi Berhasil Ditolak!"
                    }
                    this._matSnackBar.open(
                        message,
                        "Info",
                        {
                            verticalPosition: "top",
                            duration: 5000,
                        }
                    );
                    setTimeout(() => {
                        this.onClose();
                    }, 500);
                }
            })
        }
    }

}
