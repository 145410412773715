// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    // versionCheckURL : 'https://opr.pt-ssss.com/version.json',
    hmr: false,
    GOOGLE_MAPS_API_KEY: 'AIzaSyDvPEuO7s2Nrx3yE98eBKSK6AxVMhEkbkI',
    firebase: {
        apiKey: "AIzaSyDvPEuO7s2Nrx3yE98eBKSK6AxVMhEkbkI",
        authDomain: "opr-s4-1573638933191.firebaseapp.com",
        databaseURL: "https://opr-s4-1573638933191.firebaseio.com",
        projectId: "opr-s4-1573638933191",
        storageBucket: "opr-s4-1573638933191.appspot.com",
        messagingSenderId: "466806671531",
        appId: "1:466806671531:web:9a750d9d36edbc25954fee",
        measurementId: "G-T7FN33V4N5"
    },
    serverKey: "AAAAbK_YiKs:APA91bFhWTq4cBMnZYc_tAUS76QB3-LnQGV_UMqVehA4qcfwXamFjluBA3sbc9YAp9d720NEhL8zidN2cpzUriJS2c95YBL3Jtd6FheZ4lOlnLj-ti3l4rxLB7DpEGd9FiitfuROYqnD",
    firebaseToken: "",
    baseUrlroot: "https://dev-apiopr.pt-ssss.com",
    baseUrl: "https://dev-apiopr.pt-ssss.com/api",
    mandiriAPI: "https://dev-mandiriapi.pt-ssss.com",
    logkar: {
      API: "https://dev-public-api.logkar.com",
      logkarS4: {
        clientID: "l2StnHrPveot",
        token: "d36ed37b5e37217cf9fdc0f6d7d7d163e0d606b82acf9d40fc24874369366d33",
        companyID: "S4"
      },
      logkarMDP: {
        clientID: "NEh6oKbLM79i",
        token: "126f26df519ad6c7ef7ba7c357d30873f5df15d5a3d8e39279b0971200e26ad4",
        companyID: "MDP"
      }
    },
    statusApp: "Beta"
}

/*
// URL Development Logkar (dev.logkar.com) -> User ID: integrationtesting0@gmail.com -> Password: qwerty123
        private string URL = "https://dev-public-api.logkar.com";
        private string ClientID = "l2StnHrPveot";
        private string APIToken = "d36ed37b5e37217cf9fdc0f6d7d7d163e0d606b82acf9d40fc24874369366d33";

        // URL Production Logkar (logkar.com) -> User ID: stefan@pt-ssss.com -> Password: 1234wxyz
        //private string URL = "https://public-api.logkar.com";
        //private string ClientID = "clvQQKyPvaQq";
        //private string APIToken = "04cc43722b8f846398370542a52e17a6d4274af2ab4ab214fe82d7057c8d87ad";
*/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

// Cabang Testing
// export const environment = {
//     production: true,
//     versionCheckURL : 'https://oprdev.pt-ssss.com/version.json',
//     hmr: false,
//     GOOGLE_MAPS_API_KEY: 'AIzaSyDvPEuO7s2Nrx3yE98eBKSK6AxVMhEkbkI',
//     firebase: {
//         apiKey: "AIzaSyDvPEuO7s2Nrx3yE98eBKSK6AxVMhEkbkI",
//         authDomain: "opr-s4-1573638933191.firebaseapp.com",
//         databaseURL: "https://opr-s4-1573638933191.firebaseio.com",
//         projectId: "opr-s4-1573638933191",
//         storageBucket: "opr-s4-1573638933191.appspot.com",
//         messagingSenderId: "466806671531",
//         appId: "1:466806671531:web:9a750d9d36edbc25954fee",
//         measurementId: "G-T7FN33V4N5"
//     },
//     serverKey: "AAAAbK_YiKs:APA91bFhWTq4cBMnZYc_tAUS76QB3-LnQGV_UMqVehA4qcfwXamFjluBA3sbc9YAp9d720NEhL8zidN2cpzUriJS2c95YBL3Jtd6FheZ4lOlnLj-ti3l4rxLB7DpEGd9FiitfuROYqnD",
//     firebaseToken: ""
// };

// Production
// export class AppSettings {
//     baseUrlroot: string = "https://apiopr.pt-ssss.com"
//     baseUrl: string = "https://apiopr.pt-ssss.com/api"
//     version: string = version
//     appname: string = "OPR Logistics"
//     baseUrlRFID: string = "http://localhost:5000"
// }