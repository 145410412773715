import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InjectVoucherModalModule } from './inject-voucher-modal/inject-voucher-modal.module';
import { KatruHilangModule } from './katru-hilang/katru-hilang.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    InjectVoucherModalModule,
    KatruHilangModule
  ]
})
export class CommonOPRModule { }
