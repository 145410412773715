export const locale = {
    lang: 'en',
    data: {
        'NAV': {
            'APPLICATIONS': 'Applications',
            'CUSTOMERS': 'Customers',
            'CONTAINER': 'EE',
            'SAMPLE'        : {
                'TITLE': 'Sample',
                'BADGE': '25'
            }
        }
    }
};
