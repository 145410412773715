<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">Masuk Ke Cabang</span>
            <button mat-icon-button (click)="_matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="m-0">
        <div fxLayout="row">
            <div fxFlex="100">
                <mat-table [dataSource]="dataSource" matSort matSortActive="comloc_id">
                    <ng-container matColumnDef="comloc_id">
                        <mat-header-cell *matHeaderCellDef class="mat-header-sticky" mat-sort-header>Kode Cabang
                        </mat-header-cell>
                        <mat-cell class="description-cell" *matCellDef="let data">{{data.comLocId }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="perusahaan_nama">
                        <mat-header-cell *matHeaderCellDef class="mat-header-sticky" mat-sort-header>Perusahaan
                        </mat-header-cell>
                        <mat-cell class="duration-cell" *matCellDef="let data">{{data.company_Name}}</mat-cell>
                    </ng-container>


                    <ng-container matColumnDef="comloc_nama">
                        <mat-header-cell *matHeaderCellDef class="mat-header-sticky" mat-sort-header>Nama Cabang
                        </mat-header-cell>
                        <mat-cell class="duration-cell" *matCellDef="let data"><button  mat-raised-button color="accent" (click)="onClickGantiCabang(data,true)">{{data.location_Name}} <mat-icon>play_arrow</mat-icon></button></mat-cell>
                    </ng-container>

                    <!-- <ng-container matColumnDef="aksi">
                        <mat-header-cell *matHeaderCellDef class="mat-header-sticky" mat-sort-header>Aksi
                        </mat-header-cell>
                        <mat-cell class="duration-cell" *matCellDef="let data">
                            <button mat-raised-button color="accent" class="button"
                                (click)="onClickGantiCabang(data,true)" mat-dialog-close>
                                <mat-icon>store</mat-icon>
                                <mat-icon>play_arrow</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container> -->

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple></mat-row>
                </mat-table>
                <mat-paginator #paginator [pageIndex]="0" [pageSize]="15"
                    [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>