<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="pacman">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
  
<div style="width: 25vw;">
  <h2 mat-dialog-title>{{title}} </h2>
  <mat-dialog-content>
    <mat-form-field appearance="outline" *ngIf="status === 2" floatLabel="always" fxFlex="100" fxFlex.xs="100"
      class="pr-12">
      <mat-label>Alasan</mat-label>
      <textarea matInput placeholder="" [(ngModel)]="reason" minlength="10" maxlength="200"></textarea>
      <mat-hint align="end">Minimal 10 karakter dari
        {{ reason.length ||
                0
        }}/200</mat-hint>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="left">
    <button mat-raised-button *ngIf="status === 1" class="teal" (click)="onConfirmClick(status)"
      tabindex="1">SETUJU</button>
    <button mat-raised-button *ngIf="status === 2" color="warn" (click)="onConfirmClick(status)"
      [disabled]="reason.length <= 10" tabindex="1">TOLAK</button>
    <button mat-raised-button mat-dialog-close tabindex="-1">{{cancelButtonText}}</button>
  </mat-dialog-actions>
</div>