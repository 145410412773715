<div fxLayout="column" fxFlex fusePerfectScrollbar>
    <div fxLayout="row">
        <mat-list class="date" cdkFocusRegionStart>
            <h3 matSubheader cdkFocusInit>
                <span>Today</span>
            </h3>
            <div class="secondary-text mat-display-1 mb-0 p-16">
                <div class="mb-12">
                    {{date | date:'EEEE'}}
                </div>
                <div fxLayout="row" fxLayoutAlign="start start">
                    <span> {{date | date:'d'}}</span>
                    <span class="mat-subheading-1">th</span>
                    <span> {{date | date:'MMMM'}}</span>
                </div>
            </div>
        </mat-list>
    </div>

    <mat-divider cdkFocusRegionEnd></mat-divider>

    <div fxLayout="row">
        <mat-list dense class="infoList">
            <h3 matSubheader>
                <span>What's New?</span>
            </h3>
            <div fusePerfectScrollbar>
                <div class="container">
                    <div class="info" *ngFor="let data of version_info_list">
                        <div class="infoContent">
                            <h3>{{ data.version }}</h3>
                            <p><small>{{ data.created_time }}</small> <br><small> {{ data.info }} </small></p>
                        </div>
                    </div>
                </div>
            </div>
            
        </mat-list>
    </div>

    <mat-divider></mat-divider>

    <div fxLayout="row" style="bottom: 0; margin-bottom: 10px; position: fixed;">
        <mat-list>
            <div class="info2">
                <div class="infoContent2">
                    <h3>App Version</h3>
                    <p>{{ last_version }}</p>
                </div>
            </div>
        </mat-list>
    </div>

    <!-- <mat-list dense>
        <h3 matSubheader>
            <span>Notes</span>
        </h3>
        <mat-list-item *ngFor="let note of notes">
            <h3 matLine>{{note.title}}</h3>
            <p matLine class="secondary-text">{{note.detail}}</p>
        </mat-list-item>
    </mat-list>

    <mat-divider></mat-divider> -->

    <!-- <mat-list dense>
        <h3 matSubheader>
            <span>Quick Settings</span>
        </h3>
        <mat-list-item>
            <mat-slide-toggle fxFlex class="mat-primary" [(ngModel)]="settings.notify" aria-label="Notifications"
                labelPosition="before">
                <h3>Notifications</h3>
            </mat-slide-toggle>
        </mat-list-item>
        <mat-list-item>
            <mat-slide-toggle fxFlex class="mat-accent" [(ngModel)]="settings.cloud" aria-label="Cloud"
                labelPosition="before">
                <h3>Cloud Sync</h3>
            </mat-slide-toggle>
        </mat-list-item>
        <mat-list-item>
            <mat-slide-toggle fxFlex class="mat-warn" [(ngModel)]="settings.retro" aria-label="Retro Thrusters"
                labelPosition="before">
                <h3>Retro Thrusters</h3>
            </mat-slide-toggle>
        </mat-list-item>
    </mat-list> -->
</div>