import { Routes } from "@angular/router";

export const dashboardRoutes: Routes = [
  {
      path: 'analytics',
      loadChildren: () =>
          import('@dashboard/analytics/analytics.module').then(
              (m) => m.AnalyticsDashboardModule
          ),
  },
  {
      path: 'approval-transaksi',
      loadChildren: () =>
          import(
              '@dashboard/approval-transaksi/approval-transaksi.module'
          ).then((m) => m.ApprovalTransaksiModule),
  },
  {
      path: 'gps-map',
      loadChildren: () =>
          import('@dashboard/gps-map/gps-map.module').then(
              (m) => m.GpsMapModule
          ),
  },
  {
      path: 'statistics',
      loadChildren: () =>
          import('@dashboard/statistics/statistics.module').then(
              (m) => m.StatisticsModule
          ),
  },
]