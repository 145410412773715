export class PushNotification {
    notification: Notification
    to: string
}
export class Notification {
    title: string
    body: string
    data: any
    click_action: string
}

export class NotificationFireStore {
    body: any;
    title: any;
    click_action: string;
    transactionId: number;
    link: string;
    type: string;
}
