import { Routes } from "@angular/router";

export const homeRoutes: Routes = [
  {
      path: 'coming-soon',
      loadChildren: () =>
          import('@home/coming-soon/coming-soon.module').then(
              (m) => m.ComingSoonModule
          ),
  },
  {
      path: 'forgot-password',
      loadChildren: () =>
          import('@home/forgot-password/forgot-password.module').then(
              (m) => m.ForgotPasswordModule
          ),
  },
  {
      path: 'login',
      loadChildren: () =>
          import('@home/login/login.module').then(
              (m) => m.LoginModule
          ),
  },
  {
      path: 'mail-confirm',
      loadChildren: () =>
          import('@home/mail-confirm/mail-confirm.module').then(
              (m) => m.MailConfirmModule
          ),
  },
  {
      path: 'register',
      loadChildren: () =>
          import('@home/register/register.module').then(
              (m) => m.RegisterModule
          ),
  },
  {
      path: 'reset-password',
      loadChildren: () =>
          import('@home/reset-password/reset-password.module').then(
              (m) => m.ResetPasswordModule
          ),
  },
]