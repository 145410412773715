import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AlertService } from 'app/service/common/alert/alert.service';
import { PublicService } from 'app/service/public.service';
import { CabangInfoViewModel } from 'app/view-model/cabang-info-model';

@Component({
    selector: 'app-ganti-cabang',
    templateUrl: './ganti-cabang.component.html',
    styleUrls: ['./ganti-cabang.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class GantiCabangComponent implements OnInit {
    title = 'app';
    cabangStorage = new CabangInfoViewModel;
    cabangList = JSON.parse(localStorage.getItem('currentUser')).ut_UserComlocRole;
    searchBox: string;
    pageType: string;
    displayedColumns: string[] = ['comloc_id', 'perusahaan_nama', 'comloc_nama'];
    dataSource: MatTableDataSource<any>;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    transporter_id = JSON.parse(localStorage.getItem('currentUser')).transporter_Id;
    navigation: any;
    menu: Array<any> = [];

    constructor(
        public _matDialogRef: MatDialogRef<GantiCabangComponent>,
        public _publicService: PublicService,
        private router: Router,
        private dialog: MatDialog,
        private _fuseNavigationService: FuseNavigationService,
        private _AlertService: AlertService
    ) { }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource(this.cabangList);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator.firstPage();
    }
    onClickGantiCabang(cabang, is_login: boolean) {
        this.cabangStorage.comloc_id = cabang.comLocId;
        this.cabangStorage.comloc_nama = cabang.location_Name;
        this.cabangStorage.perusahaan_nama = cabang.company_Name;
        localStorage.setItem('cabang', JSON.stringify(this.cabangStorage));
        const dialogRef = this.dialog.closeAll();
        this._fuseNavigationService.unregister('mainmenu');

        this._publicService.getMenu(this.cabangStorage.comloc_id).subscribe(resp => {
            // Get default navigation
            this.navigation = resp;
            localStorage.setItem('menu', JSON.stringify(resp));
            // Register the navigation to the service
            this._fuseNavigationService.register('mainmenu', this.navigation);
            // Set the main navigation as our current navigation
            this._fuseNavigationService.setCurrentNavigation('mainmenu');
        });
        this.pageType = this._publicService.pageType;

        if (this.pageType === 'login') {
            this.router.navigateByUrl('/dashboard/analytics');
        }
        else if (this.pageType === 'change') {
            this._AlertService.Global_Alert('success', 'Berhasil', 'Cabang Berhasil Diubah menjadi ' + this.cabangStorage.comloc_nama);
            setTimeout(() => {
                location.reload();
            }, 2000);
        }
    }
}