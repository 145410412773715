import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseConfigService } from "@fuse/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { AppSettings } from 'app/app.settings';
import { AppSettingsService } from 'app/app.settings.service';
import { GantiCabangComponent } from "app/main/pengaturan/ganti-cabang/ganti-cabang.component";
import { navigation } from "app/navigation/navigation";
import { DataService } from 'app/service/data.service';
import { LoginService } from "app/service/login.service";
import { PublicService } from "app/service/public.service";
import { CabangInfoViewModel } from "app/view-model/cabang-info-model";
import { UserInfoViewModel } from "app/view-model/user-info-model";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";


@Component({
    selector: "toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: ["./toolbar.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit, OnDestroy {
    settings: AppSettings;
    app: string;
    version: string;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    userInfo: UserInfoViewModel = new UserInfoViewModel;
    cabangInfo: CabangInfoViewModel = new CabangInfoViewModel;
    comloc_id: string = JSON.parse(localStorage.getItem("cabang")).comloc_id || "";
    // Private
    private _unsubscribeAll: Subject<any>;
    @HostListener('window:storage', ['$event'])
    onStorageChange(ev: StorageEvent) {
        if(ev.key === 'cabang') {
          this.cabangInfo = JSON.parse(ev.newValue);
          setTimeout(() => {
              location.reload();
          }, 2000);
        }
    }
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        public _loginService: LoginService,
        private dialog: MatDialog,
        private _router: Router,
        public _publicService: PublicService,
        private dataService: DataService,
        private appSettingsService:AppSettingsService
    ) {
        this.appSettingsService.getSettings().subscribe(settings => this.settings = settings, () => null, () => {
            this.app = this.settings.appname;
            this.version = this.settings.version;
        });
        // Set the defaults
        this.userStatusOptions = [
            {
                title: "Online",
                icon: "icon-checkbox-marked-circle",
                color: "#4CAF50"
            },
            {
                title: "Away",
                icon: "icon-clock",
                color: "#FFC107"
            },
            {
                title: "Do not Disturb",
                icon: "icon-minus-circle",
                color: "#F44336"
            },
            {
                title: "Invisible",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#BDBDBD"
            },
            {
                title: "Offline",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#616161"
            }
        ];

        this.languages = [
            {
                id: "en",
                title: "English",
                flag: "us"
            },
            {
                id: "tr",
                title: "Turkish",
                flag: "tr"
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        if (localStorage.currentUser && localStorage.cabang) {
            this.userInfo = JSON.parse(localStorage.currentUser);
            this.cabangInfo = JSON.parse(localStorage.cabang);
            //this.getNotificationsList(this.userInfo.email);
        }

        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(settings => {
                this.horizontalNavbar =
                    settings.layout.navbar.position === "top";
                this.rightNavbar = settings.layout.navbar.position === "right";
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {
            id: this._translateService.currentLang
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }
    openGantiCabang() {
        const dialogRef = this.dialog.open(GantiCabangComponent, {
            panelClass: 'ganti-cabang-dialog',
            width: '720px'
        });
        this._publicService.pageType = "change";
        dialogRef.afterClosed().subscribe(result => {
            this.userInfo = JSON.parse(localStorage.currentUser);
            this.cabangInfo = JSON.parse(localStorage.cabang);
        });
    }
    changePassword() {
        this._router.navigate(["pengaturan/ganti-password/"]);
    }

    // getNotificationsList(email: string) {
    //     this._publicService.getNotificationList(email).snapshotChanges().pipe(
    //         map(changes =>
    //             changes.map(c =>
    //                 ({ key: c.payload.doc.id, ...c.payload.doc.data() })
    //             )
    //         )
    //     ).subscribe(Notifications => {
    //         this._publicService.ListNotification = Notifications;
    //     });
    // }
    onClickNotif(notif) {
        this._publicService.getTransaksiByID(notif.type, notif.transactionId, this.comloc_id).subscribe((data) => {
            
            if (notif.type == "SUJ") {
                this.dataService.setOption(data);
                this.dataService.pageTypeModal = "approve";
                this._router.navigate(["/transaksi/armada-berangkat/suj/approve"]);
            } else if (notif.type == "SUT") {
                this.dataService.setOption(data);
                this.dataService.pageTypeModal = "approve";
                this._router.navigate([
                    "transaksi/armada-berangkat/non-trado/slip-uang-tambahan-edit",
                ]);
            }
            else if (notif.type == "SUG") {
                this.dataService.setOption(data);
                this.dataService.pageTypeModal = "approve";
                this.dataService.approve = true
                this._router.navigate([
                    "transaksi/uang-gantung/non-trado/uang-gantung-data",
                ]);
            }
            else if (notif.type == "SUJTrado") {
                this.dataService.setOption(data);
                this.dataService.pageTypeModal = "approve";
                this._router.navigate(["/transaksi/armada-berangkat/trado/suj/approve"]);
            }
            else if (notif.type == "SUTTrado") {
                this.dataService.setOption(data);
                this.dataService.pageTypeModal = "approve";
                this._router.navigate([
                    "transaksi/armada-berangkat/trado/slip-uang-tambahan-edit",
                ]);
            }
            else if (notif.type == "SUGTrado") {
                this.dataService.setOption(data);
                this.dataService.pageTypeModal = "approve";
                this.dataService.approve = true
                this._router.navigate([
                    "transaksi/uang-gantung/trado/uang-gantung-trado-data",
                ]);
            }

        })
    }
}
