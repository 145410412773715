import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { JwtModule } from '@auth0/angular-jwt';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule, FuseWidgetModule } from '@fuse/components';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from 'app/app.component';
import { fuseConfig } from 'app/fuse-config';
import { LayoutModule } from 'app/layout/layout.module';
import { JwtInterceptorService } from 'app/service/jwt-interceptor.service';
import { environment } from 'environments/environment';
import { CommonOPRModule } from './main/common/common-opr.module';
import { Error404Module } from './main/errors/404/error-404.module';
import { GantiCabangModule } from './main/pengaturan/ganti-cabang/ganti-cabang.module';
import { ApproveModule } from './main/transaksi/approve/approve.module';
import { appRoutes } from './routes/app.routes';
import { AppDateAdapter, APP_DATE_FORMATS } from './utils/converter';

const googleMapsParams = {
    apiKey: environment.GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
    language: 'id',
    // region: 'DE'
};
const routes: Routes = appRoutes;

@NgModule({
    declarations: [AppComponent],
    imports: [
        ApproveModule,
        BrowserModule.withServerTransition({ appId: 'demo' }),
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(routes, {
            onSameUrlNavigation: 'reload',
            relativeLinkResolution: 'legacy',
        }),
        TranslateModule.forRoot(),
        CommonOPRModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseWidgetModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        GantiCabangModule,
        Error404Module,
        // App modules & Component
        LayoutModule,
        AgmCoreModule.forRoot(googleMapsParams),
        MatGoogleMapsAutocompleteModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: jwtTokenGetter,
            },
        }),
    ],
    providers: [
      DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptorService,
            multi: true,
        },
        // FOR NOTIFICATION
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
export function jwtTokenGetter() {
    return localStorage.getItem('currentUser')
        ? JSON.parse(localStorage.getItem('currentUser')).token
        : null;
}
