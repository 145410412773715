<!-- OPR_Future-->
<mat-toolbar class="p-0 mat-elevation-z1">

    <div class="toolbar" fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

        <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="white-500-fg">menu</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <!-- <img class="logo-icon" src="assets/images/logos/fuse.svg"> -->
                    <img class="logo-icon" src="assets/images/logos/SSSS.svg">
                </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <!-- <img class="logo-icon" src="assets/images/logos/fuse.svg"> -->
                    <span>v.{{version}}</span>
                </div>
            </div>
            <div class="px-8 px-md-16">
                <!-- <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts> -->
                <span class="font-weight-700">{{app}}</span>
            </div>

        </div>

        <div class="toolbar-separator"></div>

        <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar>
            <div class="toolbar-separator"></div> -->
            <button mat-button fxHide fxShow.gt-xs (click)="openGantiCabang()" class="language-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon>store</mat-icon>
                    <span class="iso text-uppercase">{{cabangInfo?.comloc_nama }} -
                        {{cabangInfo?.perusahaan_nama}}</span>
                </div>
            </button>
            <div class="toolbar-separator"></div>

            <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="notification">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon *ngIf="_publicService.ListNotification.length > 0"
                        matBadge="{{_publicService.ListNotification.length}}" matBadgeColor="warn">notifications
                    </mat-icon>
                    <mat-icon *ngIf="_publicService.ListNotification.length == 0">notifications
                    </mat-icon>
                </div>
            </button>

            <mat-menu #notification="matMenu" overlapTrigger="false" class="my-full-width-menu">
                <mat-nav-list>
                    <mat-list-item role="listitem" style="cursor: pointer;"
                        *ngFor="let notif of _publicService.ListNotification" (click)="onClickNotif(notif)">

                        <div mat-line>
                            {{notif.title}}
                        </div>
                        <p mat-line style="white-space:unset !important">
                            {{notif.body}}
                        </p>
                    </mat-list-item>
                </mat-nav-list>
            </mat-menu>

            <div class="toolbar-separator"></div>

            <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <img class="avatar mr-0 mr-sm-16"
                        [src]="userInfo.foto == null || userInfo.foto == '' ? 'assets/images/avatars/profile.jpg' : userInfo.foto">
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{userInfo.name}}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" overlapTrigger="false">

                <button mat-menu-item>
                    <mat-icon>account_circle</mat-icon>
                    <span>Profil</span>
                </button>

                <button mat-menu-item class="" (click)="changePassword()">
                    <mat-icon>vpn_key</mat-icon>
                    <span>Ganti Password</span>
                </button>

                <button mat-menu-item class="" (click)="_loginService.Logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Keluar</span>
                </button>
            </mat-menu>

            <!-- <button mat-button fxHide fxShow.gt-xs
            class="language-button"
                [matMenuTriggerFor]="languageMenu">
            <div fxLayout="row" fxLayoutAlign="center center">
                <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
            </div>
            </button>

            <mat-menu #languageMenu="matMenu" overlapTrigger="false">

                <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                    <span fxLayout="row" fxLayoutAlign="start center">
                        <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                        <span class="iso">{{lang.title}}</span>
                    </span>
                </button>

            </mat-menu> -->

            <div class="toolbar-separator" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="quick-panel-toggle-button" (click)="toggleSidebarOpen('quickPanel')"
                aria-label="Toggle quick panel">
                <mat-icon class="white-500-fg">format_list_bulleted</mat-icon>
            </button>

            <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

            <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar"
                (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>
        </div>
    </div>
</mat-toolbar>