export const environment = {
    production: true,
    hmr: false,
    GOOGLE_MAPS_API_KEY: 'AIzaSyDvPEuO7s2Nrx3yE98eBKSK6AxVMhEkbkI',
    versionCheckURL: 'https://opr.pt-ssss.com/version.json',
    firebase: {
        apiKey: "AIzaSyDvPEuO7s2Nrx3yE98eBKSK6AxVMhEkbkI",
        authDomain: "opr-s4-1573638933191.firebaseapp.com",
        databaseURL: "https://opr-s4-1573638933191.firebaseio.com",
        projectId: "opr-s4-1573638933191",
        storageBucket: "opr-s4-1573638933191.appspot.com",
        messagingSenderId: "466806671531",
        appId: "1:466806671531:web:9a750d9d36edbc25954fee",
        measurementId: "G-T7FN33V4N5"
    },
    serverKey: "AAAAbK_YiKs:APA91bFhWTq4cBMnZYc_tAUS76QB3-LnQGV_UMqVehA4qcfwXamFjluBA3sbc9YAp9d720NEhL8zidN2cpzUriJS2c95YBL3Jtd6FheZ4lOlnLj-ti3l4rxLB7DpEGd9FiitfuROYqnD",
    firebaseToken: "",
    baseUrlroot: "https://apiopr.pt-ssss.com",
    baseUrl: "https://apiopr.pt-ssss.com/api",
    mandiriAPI: "https://mandiriapi.pt-ssss.com",
    logkar: {
      API: "https://public-api.logkar.com",
      logkarS4: {
        clientID: "clvQQKyPvaQq",
        token: "04cc43722b8f846398370542a52e17a6d4274af2ab4ab214fe82d7057c8d87ad",
        companyID: "S4"
      },
      logkarMDP: {
        clientID: "FCTbWWmyIBjt",
        token: "f242800f051d00bd0698538cf976a1e89581ddd6cce44bf6389b37a7e0fce718",
        companyID: "MDP"
      }
    },
    statusApp: "Release"
    // logkar: {
    //   API: "https://public-api.logkar.com",
    //   clientID: "clvQQKyPvaQq",
    //   token: "04cc43722b8f846398370542a52e17a6d4274af2ab4ab214fe82d7057c8d87ad",
    //   companyID: "S4"
    // }

    // bu ini untuk acc credential MDP ya bu
    // Client ID : FCTbWWmyIBjt
    // API KEY : f242800f051d00bd0698538cf976a1e89581ddd6cce44bf6389b37a7e0fce718
};
