import { Injectable } from '@angular/core';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})

export class AlertService {

    constructor(

    ) { }

    // SweetAlert2 Zone -----------------------------------------------------------------------------------
    // icon: success, warning, question, error.

    Global_Alert(icon: any, title: any, text: any): Promise<SweetAlertResult> {
        return Swal.fire({
            icon: icon,
            title: title,
            // text: text,
            html: text,
            // timer: 20000 // Auto close in 20 seconds
        })
    }

    Global_Confirm(title: string, text: string, icon: any, confirmText: string, cancelText: string): Promise<SweetAlertResult> {
      return Swal.fire({
          title: title,
          text: text,
          icon: icon,
          showCancelButton: true,
          confirmButtonColor: '#E42728',
          cancelButtonColor: '#adb5bd',
          confirmButtonText: confirmText,
          cancelButtonText: cancelText,
          reverseButtons: true,
          customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-danger ml-1'
          }
      })
    }

    Global_Alert_Without_Text(icon: any, title: any) {
        return Swal.fire({
            icon: icon,
            title: title,
            showConfirmButton: false,
            timer: 3000
        })
    }

    Global_Alert_Preview_Image(imageUrl: any, fileName: any, fileSize: any) {
        Swal.fire({
            title: fileName,
            text: fileSize,
            imageUrl: imageUrl,
            width: '88%',
            imageHeight: 768,
            imageAlt: 'Attachment Image',
        })
    }

    Alert_Multiple(str) {
        Swal.fire({
            html: '<pre>' + str + '</pre>',
            customClass: {
                popup: 'format-pre'
            }
        });
    }

    Input(title: string, inputLabel: string) {
      return Swal.fire({
        title: title,
        input: 'textarea',
        inputLabel: inputLabel,
        inputValidator: (value) => {
          if(!value) return 'Silahkan isi alasan tolak'
        }
      })
    }
}