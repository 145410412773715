import { Routes } from "@angular/router";

export const transaksiRoutes: Routes = [
  {
      path: 'kontrak-data',
      loadChildren: () =>
          import(
              '@transaksi/kontrak/kontrak-data/kontrak-data.module'
          ).then((m) => m.KontrakDataModule),
  },
  {
      path: 'kontrak-tambah',
      loadChildren: () =>
          import(
              '@transaksi/kontrak/kontrak-tambah/kontrak-tambah.module'
          ).then((m) => m.KontrakTambahModule),
  },
  {
      path: 'slip-uang-jalan',
      loadChildren: () =>
          import(
              '@transaksi/armada-berangkat/non-trado/slip-uang-jalan-data/slip-uang-jalan-data.module'
          ).then((m) => m.SlipUangJalanDataModule),
  },
  {
      path: 'armada-berangkat/suj',
      loadChildren: () =>
          import(
              '@transaksi/armada-berangkat/non-trado/slip-uang-jalan/slip-uang-jalan.module'
          ).then((m) => m.SlipUangJalanModule),
  },
  {
      path: 'armada-berangkat/non-trado',
      children: [
          {
              path: 'slip-uang-tambahan-data',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/non-trado/slip-uang-tambahan-data/slip-uang-tambahan-data.module'
                  ).then((m) => m.SlipUangTambahanDataModule),
          },
          {
              path: 'slip-uang-tambahan-tambah',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/non-trado/slip-uang-tambahan-tambah/slip-uang-tambahan-tambah.module'
                  ).then((m) => m.SlipUangTambahanTambahModule),
          },
          {
              path: 'slip-uang-tambahan-edit',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/non-trado/slip-uang-tambahan-tambah/slip-uang-tambahan-tambah.module'
                  ).then((m) => m.SlipUangTambahanTambahModule),
          },
          {
              path: 'retur-slip-uang-jalan-data',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/non-trado/retur-slip-uang-jalan-data/retur-slip-uang-jalan-data.module'
                  ).then((m) => m.ReturSlipUangJalanDataModule),
          },
          {
              path: 'retur-slip-uang-jalan-data',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/non-trado/retur-slip-uang-jalan-data/retur-slip-uang-jalan-data.module'
                  ).then((m) => m.ReturSlipUangJalanDataModule),
          },
          {
              path: 'retur-slip-uang-jalan-tambah',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/non-trado/retur-slip-uang-jalan-tambah/retur-slip-uang-jalan-tambah.module'
                  ).then((m) => m.ReturSlipUangJalanTambahModule),
          },
          {
              path: 'bpuj-data-non-trado',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/non-trado/bpuj-data-non-trado/bpuj-data-non-trado.module'
                  ).then((m) => m.BpujDataNonTradoModule),
          },
      ],
  },
  {
      path: 'armada-kembali-data',
      loadChildren: () =>
          import(
              '@transaksi/armada-kembali/armada-kembali-data/armada-kembali-data.module'
          ).then((m) => m.ArmadaKembaliDataModule),
  },
  {
      path: 'armada-kembali',
      loadChildren: () =>
          import(
              '@transaksi/armada-kembali/armada-kembali-tambah/armada-kembali-tambah.module'
          ).then((m) => m.ArmadaKembaliTambahModule),
  },
  {
      path: 'revisi-do-uang-jalan',
      children: [
          {
              path: 'revisi-do-uang-jalan-data',
              loadChildren: () =>
                  import(
                      '@transaksi/revisi-do-uang-jalan/revisi-do-uang-jalan-data/revisi-do-uang-jalan-data.module'
                  ).then((m) => m.RevisiDoUangJalanDataModule),
          },
          {
              path: 'tambah',
              loadChildren: () =>
                  import(
                      '@transaksi/revisi-do-uang-jalan/revisi-do-uang-jalan-tambah/revisi-do-uang-jalan-tambah.module'
                  ).then((m) => m.RevisiDoUangJalanTambahModule),
          },
          {
              path: 'detail',
              loadChildren: () =>
                  import(
                      '@transaksi/revisi-do-uang-jalan/revisi-do-uang-jalan-tambah/revisi-do-uang-jalan-tambah.module'
                  ).then((m) => m.RevisiDoUangJalanTambahModule),
          },
      ],
  },
  {
      path: 'armada-gantung-data',
      loadChildren: () =>
          import(
              '@transaksi/armada-gantung/armada-gantung-data/armada-gantung-data.module'
          ).then((m) => m.ArmadaGantungDataModule),
  },
  {
      path: 'armada-gantung-tambah',
      loadChildren: () =>
          import(
              '@transaksi/armada-gantung/armada-gantung-tambah/armada-gantung-tambah.module'
          ).then((m) => m.ArmadaGantungTambahModule),
  },
  {
      path: 'uang-gantung/non-trado/uang-gantung-data',
      loadChildren: () =>
          import(
              '@transaksi/uang-gantung/non-trado/uang-gantung-data/uang-gantung-data.module'
          ).then((m) => m.UangGantungDataModule),
  },
  {
      path: 'pengajuan-pembayaran-ekspedisi',
      loadChildren: () =>
          import(
              '@transaksi/EMKL/pengajuan-pembayaran-ekspedisi/pengajuan-pembayaran-ekspedisi.module'
          ).then(m => m.PengajuanPembayaranEkspedisiModule),
  },
  {
      path: 'pengajuan-pembayaran-ekspedisi',
      children: [
          {
              path: 'revisi',
              loadChildren: () =>
                  import(
                      '@transaksi/EMKL/pengajuan-pembayaran-ekspedisi-revisi/pengajuan-pembayaran-ekspedisi-revisi.module'
                  ).then(m => m.PengajuanPembayaranEkspedisiRevisiModule)
          },
          {
              path: ':any',
              loadChildren: () =>
                  import(
                      '@transaksi/EMKL/pengajuan-pembayaran-ekspedisi-tambah/pengajuan-pembayaran-ekspedisi-tambah.module'
                  ).then(m => m.PengajuanPembayaranEkspedisiTambahModule)
          }
      ]

  },
  {
      path: 'retur-pengajuan-pembayaran-ekspedisi',
      children: [
          {
              path: '',
              loadChildren: () => import(
                  '@transaksi/EMKL/pengajuan-pembayaran-ekspedisi-retur/pengajuan-pembayaran-ekspedisi-retur.module'
              ).then((m) => m.PengajuanPembayaranEkspedisiReturModule)
          },
          {
              path: ':any',
              loadChildren: () => import(
                  '@transaksi/EMKL/pengajuan-pembayaran-ekspedisi-retur-tambah/pengajuan-pembayaran-ekspedisi-retur-tambah.module'
              ).then((m) => m.PengajuanPembayaranEkspedisiReturTambahModule)
          }
      ]
  },
  {
      path: 'penjelasan-armada-gantung-data',
      loadChildren: () =>
          import(
              '@transaksi/penjelasan-armada-gantung/penjelasan-armada-gantung-data/penjelasan-armada-gantung-data.module'
          ).then((m) => m.PenjelasanArmadaGantungDataModule),
  },
  {
      path: 'penjelasan-armada-gantung-tambah',
      loadChildren: () =>
          import(
              '@transaksi/penjelasan-armada-gantung/penjelasan-armada-gantung-tambah/penjelasan-armada-gantung-tambah.module'
          ).then((m) => m.PenjelasanArmadaGantungTambahModule),
  },
  {
      path: 'list-cetak-do-sharing',
      loadChildren: () =>
          import(
              '@transaksi/list-cetak-do-sharing/list-cetak-do-sharing-data/list-cetak-do-sharing.module'
          ).then((m) => m.ListCetakDoSharingModule),
  },
  {
      path: 'kontrak-trado-data',
      loadChildren: () =>
          import(
              '@transaksi/kontrak-trado/kontrak-trado-data/kontrak-trado-data.module'
          ).then((m) => m.KontrakTradoDataModule),
  },
  {
      path: 'kontrak-trado',
      loadChildren: () =>
          import(
              '@transaksi/kontrak-trado/kontrak-trado-tambah/kontrak-trado-tambah.module'
          ).then((m) => m.KontrakTradoTambahModule),
  },
  {
      path: 'kontrak-trado-kapal-kembali',
      loadChildren: () =>
          import(
              '@transaksi/kontrak-trado/kontrak-trado-kapal-kembali/kontrak-trado-kapal-kembali.module'
          ).then((m) => m.KontrakTradoKapalKembaliModule),
  },
  {
      path: 'slip-uang-jalan-trado',
      loadChildren: () =>
          import(
              '@transaksi/armada-berangkat/trado/slip-uang-jalan-data-trado/slip-uang-jalan-data-trado.module'
          ).then((m) => m.SlipUangJalanDataTradoModule),
  },
  {
      path: 'armada-berangkat/trado',
      children: [
          {
              path: '',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/trado/slip-uang-jalan-tambah-trado/slip-uang-jalan-tambah-trado.module'
                  ).then((m) => m.SlipUangJalanTambahTradoModule),
          },
          {
              path: 'slip-uang-tambahan-data',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/trado/slip-uang-tambahan-data-trado/slip-uang-tambahan-data-trado.module'
                  ).then((m) => m.SlipUangTambahanDataTradoModule),
          },
          {
              path: 'slip-uang-tambahan-tambah',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/trado/slip-uang-tambahan-tambah-trado/slip-uang-tambahan-tambah-trado.module'
                  ).then((m) => m.SlipUangTambahanTambahTradoModule),
          },
          {
              path: 'slip-uang-tambahan-edit',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/trado/slip-uang-tambahan-tambah-trado/slip-uang-tambahan-tambah-trado.module'
                  ).then((m) => m.SlipUangTambahanTambahTradoModule),
          },
          {
              path: 'retur-slip-uang-jalan-data-trado',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/trado/retur-slip-uang-jalan-data-trado/retur-slip-uang-jalan-data-trado.module'
                  ).then((m) => m.ReturSlipUangJalanDataTradoModule),
          },
          {
              path: 'retur-slip-uang-jalan-tambah-trado',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/trado/retur-slip-uang-jalan-tambah-trado/retur-slip-uang-jalan-tambah-trado.module'
                  ).then(
                      (m) => m.ReturSlipUangJalanTambahTradoModule
                  ),
          },
          {
              path: 'bpuj-data-trado',
              loadChildren: () =>
                  import(
                      '@transaksi/armada-berangkat/trado/bpuj-data-trado/bpuj-data-trado.module'
                  ).then((m) => m.BpujDataTradoModule),
          },
      ],
  },
  {
    path: 'pre-gate-in',
    loadChildren: () =>
      import(
        '@transaksi/depo-kontainer/pre-gate-in/pre-gate-in.module'
      ).then(m => m.PreGateInModule)
  },
  {
    path: 'gate-in',
    loadChildren: () => 
        import(
          '@transaksi/depo-kontainer/gate-in/gate-in.module'
        ).then(m => m.GateInModule)
  },
  {
    path: 'pre-gate-out',
    loadChildren: () => 
        import(
          '@transaksi/depo-kontainer/pre-gate-out/pre-gate-out.module'
        ).then(m => m.PreGateOutModule)
  },
  {
    path: 'gate-out',
    loadChildren: () => 
        import(
          '@transaksi/depo-kontainer/gate-out/gate-out.module'
        ).then(m => m.GateOutModule)
  },
  {
      path: 'armada-kembali-data-trado',
      loadChildren: () =>
          import(
              '@transaksi/armada-kembali-trado/armada-kembali-trado-data/armada-kembali-trado-data.module'
          ).then((m) => m.ArmadaKembaliTradoDataModule),
  },
  {
      path: 'armada-kembali-trado',
      loadChildren: () =>
          import(
              '@transaksi/armada-kembali-trado/armada-kembali-trado-tambah/armada-kembali-trado-tambah.module'
          ).then((m) => m.ArmadaKembaliTradoTambahModule),
  },
  {
      path: 'armada-gantung-trado-data',
      loadChildren: () =>
          import(
              '@transaksi/armada-gantung-trado/armada-gantung-trado-data/armada-gantung-trado-data.module'
          ).then((m) => m.ArmadaGantungTradoDataModule),
  },
  {
      path: 'armada-gantung-trado-tambah',
      loadChildren: () =>
          import(
              '@transaksi/armada-gantung-trado/armada-gantung-trado-tambah/armada-gantung-trado-tambah.module'
          ).then((m) => m.ArmadaGantungTradoTambahModule),
  },
  {
      path: 'uang-gantung/trado/uang-gantung-trado-data',
      loadChildren: () =>
          import(
              '@transaksi/uang-gantung/trado/uang-gantung-trado-data/uang-gantung-trado-data.module'
          ).then((m) => m.UangGantungTradoDataModule),
  },
  {
      path: 'penjelasan-armada-gantung-trado-data',
      loadChildren: () =>
          import(
              '@transaksi/penjelasan-armada-gantung-trado/penjelasan-armada-gantung-trado-data/penjelasan-armada-gantung-trado-data.module'
          ).then((m) => m.PenjelasanArmadaGantungTradoDataModule),
  },
  {
      path: 'penjelasan-armada-gantung-trado-tambah',
      loadChildren: () =>
          import(
              '@transaksi/penjelasan-armada-gantung-trado/penjelasan-armada-gantung-trado-tambah/penjelasan-armada-gantung-trado-tambah.module'
          ).then((m) => m.PenjelasanArmadaGantungTradoTambahModule),
  },
  {
      path: 'sub-pi-data',
      loadChildren: () =>
          import(
              '@transaksi/sub-pi/sub-pi-data/sub-pi-data.module'
          ).then((m) => m.SubPiDataModule),
  },
  {
      path: 'sub-pi',
      loadChildren: () =>
          import(
              '@transaksi/sub-pi/sub-pi-tambah/sub-pi-tambah.module'
          ).then((m) => m.SubPiTambahModule),
  },
  {
      path: 'retur-sub-pi-data',
      loadChildren: () =>
          import(
              '@transaksi/retur-sub-pi/retur-sub-pidata/retur-sub-pidata.module'
          ).then((m) => m.ReturSubPIDataModule),
  },
  {
      path: 'retur-sub-pi',
      loadChildren: () =>
          import(
              '@transaksi/retur-sub-pi/retur-sub-pitambah/retur-sub-pitambah.module'
          ).then((m) => m.ReturSubPITambahModule),
  },
  {
      path: 'sub-pi-armada-kembali',
      children: [
          {
              path: 'data',
              loadChildren: () =>
                  import(
                      '@transaksi/sub-pi-armada-kembali/sub-piarmada-kembali-data/sub-piarmada-kembali-data.module'
                  ).then((m) => m.SubPIArmadaKembaliDataModule),
          },
          {
              path: 'tambah',
              loadChildren: () =>
                  import(
                      '@transaksi/sub-pi-armada-kembali/sub-piarmada-kembali-tambah/sub-piarmada-kembali-tambah.module'
                  ).then((m) => m.SubPIArmadaKembaliTambahModule),
          },
          {
              path: 'edit',
              loadChildren: () =>
                  import(
                      '@transaksi/sub-pi-armada-kembali/sub-piarmada-kembali-tambah/sub-piarmada-kembali-tambah.module'
                  ).then((m) => m.SubPIArmadaKembaliTambahModule),
          },
          {
              path: 'detail',
              loadChildren: () =>
                  import(
                      '@transaksi/sub-pi-armada-kembali/sub-piarmada-kembali-tambah/sub-piarmada-kembali-tambah.module'
                  ).then((m) => m.SubPIArmadaKembaliTambahModule),
          },
      ],
  },
  {
      path: 'sub-kontrak-data',
      loadChildren: () =>
          import(
              '@transaksi/sub-kontrak/sub-kontrak-data/sub-kontrak-data.module'
          ).then((m) => m.SubKontrakDataModule),
  },
  {
      path: 'sub-kontrak',
      loadChildren: () =>
          import(
              '@transaksi/sub-kontrak/sub-kontrak-tambah/sub-kontrak-tambah.module'
          ).then((m) => m.SubKontrakTambahModule),
  },
  {
      path: 'retur-sub-kontrak-data',
      loadChildren: () =>
          import(
              '@transaksi/retur-sub-kontrak/retur-sub-kontrak-data/retur-sub-kontrak-data.module'
          ).then((m) => m.ReturSubKontrakDataModule),
  },
  {
      path: 'retur-sub-kontrak',
      loadChildren: () =>
          import(
              '@transaksi/retur-sub-kontrak/retur-sub-kontrak-tambah/retur-sub-kontrak-tambah.module'
          ).then((m) => m.ReturSubKontrakTambahModule),
  },
  {
      path: 'sub-spb-data',
      loadChildren: () =>
          import(
              '@transaksi/sub-spb/sub-spb-data/sub-spb-data.module'
          ).then((m) => m.SubSPBDataModule),
  },
  {
      path: 'sub-spb-tambah',
      loadChildren: () =>
          import(
              '@transaksi/sub-spb/sub-spb-tambah/sub-spb-tambah.module'
          ).then((m) => m.SubSPBTambahModule),
  },
  {
      path: 'sub-spb/upload',
      loadChildren: () =>
          import(
              '@transaksi/sub-spb/sub-spb-upload/sub-spb-upload.module'
          ).then((m) => m.SubSPBUploadModule),
  },
  {
      path: 'pemakaian-voucher-bbm',
      loadChildren: () =>
          import(
              '@transaksi/pemakaian-voucher-bbm/pemakaian-voucher-bbm-data/pemakaian-voucher-bbm-data.module'
          ).then((m) => m.PemakaianVoucherBbmDataModule),
  },
  {
      path: 'pemakaian-voucher-bbm-tambah',
      loadChildren: () =>
          import(
              '@transaksi/pemakaian-voucher-bbm/pemakaian-voucher-bbm-tambah/pemakaian-voucher-bbm-tambah.module'
          ).then((m) => m.PemakaianVoucherBbmTambahModule),
  },
  {
      path: 'pengambilan-hadiah-voucher-data',
      loadChildren: () =>
          import(
              '@transaksi/pengambilan-hadiah-voucher/pengambilan-hadiah-voucher-data/pengambilan-hadiah-voucher-data.module'
          ).then((m) => m.PengambilanHadiahVoucherDataModule),
  },
  {
      path: 'pengambilan-hadiah-voucher-tambah',
      loadChildren: () =>
          import(
              '@transaksi/pengambilan-hadiah-voucher/pengambilan-hadiah-voucher-tambah/pengambilan-hadiah-voucher-tambah.module'
          ).then((m) => m.PengambilanHadiahVoucherTambahModule),
  },
  {
      path: 'armada-berangkat-bpuj',
      loadChildren: () =>
          import(
              '@transaksi/armada-berangkat/armada-berangkat-bpuj/armada-berangkat-bpuj.module'
          ).then((m) => m.ArmadaBerangkatBpujModule),
  },
  {
      path: 'armada-berangkat-bpuj-do',
      loadChildren: () =>
          import(
              '@transaksi/armada-berangkat/armada-berangkat-bpuj-do/armada-berangkat-bpuj-do.module'
          ).then((m) => m.ArmadaBerangkatBpujDoModule),
  },
  {
      path: 'armada-berangkat-data',
      loadChildren: () =>
          import(
              '@transaksi/armada-berangkat/non-trado/armada-berangkat-data/armada-berangkat-data.module'
          ).then((m) => m.ArmadaBerangkatDataModule),
  },
  {
    path: 'ekspedisi-kembali',
    loadChildren: () => 
      import(
        '@transaksi/ekspedisi-kembali/ekspedisi-kembali.module'
      ).then((m) => m.EkspedisiKembaliModule)
  }
]