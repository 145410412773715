import { Routes } from "@angular/router";

export const pengaturanRoutes: Routes = [
  {
      path: 'akun',
      loadChildren: () =>
          import('@pengaturan/user/user-data/user-data.module').then(
              (m) => m.UserDataModule
          ),
  },
  {
      path: 'hak-akses',
      loadChildren: () =>
          import('@pengaturan/role/role.module').then(
              (m) => m.RoleModule
          ),
  },
  {
      path: 'approval',
      loadChildren: () =>
          import('@pengaturan/approval/approval.module').then(
              (m) => m.ApprovalModule
          ),
  },
  {
      path: 'version-info',
      loadChildren: () =>
          import(
              '@pengaturan/version-info/version-info-data/version-info-data.module'
          ).then((m) => m.VersionInfoDataModule),
  },
  {
      path: 'version-info-tambah',
      loadChildren: () =>
          import(
              '@pengaturan/version-info/version-info-tambah/version-info-tambah.module'
          ).then((m) => m.VersionInfoTambahModule),
  },
  {
      path: 'konfigurasi',
      loadChildren: () =>
          import(
              '@pengaturan/konfigurasi/konfigurasi-data/konfigurasi-data.module'
          ).then((m) => m.KonfigurasiDataModule),
  },
  {
      path: 'sequence-setup',
      loadChildren: () =>
          import(
              '@pengaturan/sequence-setup/sequence-setup-data/sequence-setup-data.module'
          ).then((m) => m.SequenceSetupDataModule),
  },
  {
      path: 'ganti-password',
      loadChildren: () => 
          import(
              '@pengaturan/ganti-password/ganti-password.module'
          ).then((m) => m.GantiPasswordModule)
  }
]