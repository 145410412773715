import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { AppSettings } from 'app/app.settings';
import { AppSettingsService } from 'app/app.settings.service';
import { environment } from 'environments/environment';
import { LoginService } from './login.service';
import { catchError } from 'rxjs/operators';
import { SHA256 } from 'crypto-js';
import { PublicService } from './public.service';
@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor
{
  settings: AppSettings;
  private baseUrl: string;
  private MDP_Comloc_id = [
    'BLWEM',
    'BLWM',
    'CLGEM',
    'CLGM',
    'DMIM',
    'JKTM',
    'KM',
    'KTM',
    'MRKM',
    'PTKM',
    'SBYEM',
    'SBYM',
    'SM',
    'SMP',
  ]
  private currentComloc = JSON.parse(localStorage.getItem("cabang"))
  constructor(
    private appSettingsService:  AppSettingsService,
    private _loginService: LoginService,
    private publicService: PublicService
  )
  {
    this.appSettingsService.getSettings()
      .subscribe(settings =>
             this.settings = settings,
        () => null,
        () => {
          this.baseUrl = this.settings.baseUrl;
        });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { mandiriAPI, logkar } = environment;
    let currentUser = { token: '' };
    if(localStorage.currentUser !== undefined) {
      currentUser = JSON.parse(localStorage.currentUser)
      if(request.url.indexOf('/mandiriAPI') !== -1) {
        const url = request.url.replace('/mandiriAPI','')
        request = request.clone({
          url: mandiriAPI + url,
          setHeaders: {
            Authorization: "Bearer " + currentUser.token
          }
        })
      }
      // disini handle http ke logkar
      else if(request.url.indexOf('/logkar') !== -1) {
        this.publicService.setRequest(request);
        const url = request.url.replace('/logkar', '');
        const isCompanyMDP = this.MDP_Comloc_id.some(item => item === request.body.companyId)
        if (this.currentComloc.perusahaan_nama === "PT. MARGA DINAMIK PERKASA") {

          if (request.body.hasOwnProperty('companyId')) {
            if (isCompanyMDP) {

              if (!request.body.request_code) {
                request.body.request_code = SHA256(`#${logkar.logkarMDP.clientID}#${logkar.logkarMDP.token}#`).toString()
              }
              request = request.clone({
                url: logkar.API + url,
                setHeaders: {
                  Authorization: logkar.logkarMDP.token,
                }
              })
            } else if (!isCompanyMDP) {
              if (!request.body.request_code) {
                request.body.request_code = SHA256(`#${logkar.logkarS4.clientID}#${logkar.logkarS4.token}#`).toString()
              }
              request = request.clone({
                url: logkar.API + url,
                setHeaders: {
                  Authorization: logkar.logkarS4.token,
                }
              })
            }
          } else {
            if (!request.body.request_code) {
              request.body.request_code = SHA256(`#${logkar.logkarMDP.clientID}#${logkar.logkarMDP.token}#`).toString()
            }
            request = request.clone({
              url: logkar.API + url,
              setHeaders: {
                Authorization: logkar.logkarMDP.token,
              }
            })
          }
        } else {

          if (request.body.hasOwnProperty('companyId')) {
            if (isCompanyMDP) {
              if (!request.body.request_code) {
                request.body.request_code = SHA256(`#${logkar.logkarMDP.clientID}#${logkar.logkarMDP.token}#`).toString()
              }
              request = request.clone({
                url: logkar.API + url,
                setHeaders: {
                  Authorization: logkar.logkarMDP.token,
                }
              })

            } else {
              if (!request.body.request_code) {
                request.body.request_code = SHA256(`#${logkar.logkarS4.clientID}#${logkar.logkarS4.token}#`).toString()
              }
              request = request.clone({
                url: logkar.API + url,
                setHeaders: {
                  Authorization: logkar.logkarS4.token,
                }
              })
            }
          } else {
            if (!request.body.request_code) {
              request.body.request_code = SHA256(`#${logkar.logkarS4.clientID}#${logkar.logkarS4.token}#`).toString()
            }
            request = request.clone({
              url: logkar.API + url,
              setHeaders: {
                Authorization: logkar.logkarS4.token,
              }
            })
          }
          
        }
      }
      else {
        request = request.clone({
          url: this.baseUrl + request.url,
          setHeaders: {
            Authorization: "Bearer " + currentUser.token
          }
        })
      }
      return next.handle(request).pipe(catchError((e: any) => {
        this.publicService.setError(e);
        console.log(e)
        if (e instanceof HttpErrorResponse) {
          const { error, headers, status, url } = e
          if (status == 401) {
            if(error && (error.errors as string || '').indexOf('Mandiri') !== -1) {} // unauth dr mandiri
            else if(e.message?.indexOf('logkar') !== -1 && error.code === 4015) {} // error 401 logkar , code 4015
            else if(url.includes('logSummary')) {
              // handle error buka rekapan transfer, jika unauthorize jgn logout
              // exception 'logSummary' saja jika 'mandiriapi' takutnya merambat 
            }
            else {
              // alert("401 UnAuthorized");
              this._loginService.Logout();
            }
          }
        }
        return throwError(e);
      }));
    } else {
      this._loginService.Logout();
      return EMPTY;
    }
  }
}
