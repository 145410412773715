import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PublicService } from 'app/service/public.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-katru-hilang',
  templateUrl: './katru-hilang.component.html',
  styleUrls: ['./katru-hilang.component.scss']
})
export class KatruHilangComponent implements OnInit {
  interval;
  timeLeft: number = 5;
  statusInject:boolean = false;
  title = ""
  messages: string = ""
  type_trx:string = ""
  constructor( @Inject(MAT_DIALOG_DATA) private param: any,
  public dialogRef: MatDialogRef<KatruHilangComponent>,
  private _matSnackBar: MatSnackBar,
  private _publicService: PublicService,
  private spinner: NgxSpinnerService,) {
    this.statusInject = false;
    this.title = this.param.title;
    this.type_trx = this.param.type_trx
   }

  ngOnInit() {
  }
  onCancel(tipe){
    this.dialogRef.close(this.param)
  }

  onSave(){
    this.spinner.show()
    this._publicService.saveRFIDHilang(this.type_trx,this.param.data,this.messages).subscribe(
    resp=> {
      if (resp.status) {
        this._matSnackBar.open(resp.message ,"Ok",{
          verticalPosition:'top',
          duration: 5000
      })
      } else {
        this._matSnackBar.open(resp.message ,"Ok",{
          verticalPosition:'top',
          duration: 5000
      })
      }
      this.spinner.hide();
    },
    error => {
      console.error(error);
      this._matSnackBar.open('Oops! Gagal Simpan, error: ' + error.status
      +', Pesan ' + error.statusText + ', Silahkan hubungi Administrator', "Ok", {
          verticalPosition: 'top',
          duration: 5000
      });
      this.spinner.hide();
    })
  }
}
