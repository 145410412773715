import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VersionInfoViewModel, VersionInfoFilter } from 'app/view-model/pengaturan/version-info/version-info-view-model';

@Injectable({
  providedIn: 'root'
})
export class VersionInfoService {

  onVersionInfoChanged: BehaviorSubject<any>;

  pageType: string;
  dataService: VersionInfoViewModel;

  constructor(
    private _httpClient: HttpClient
  ) {
    this.onVersionInfoChanged = new BehaviorSubject({});
  }

  // Load Version Table
  getVersionInfoTable(filter: VersionInfoFilter, sortColumn: string, sortOrder: string, pageNumber = 0, pageSize = 10): Observable<any> {
    return this._httpClient.post<any>("/VersionInfo/GetVersionInfo", { filter, sortColumn, sortOrder, pageNumber, pageSize }, { responseType: "json", observe: "response" })
      .pipe(map(response => {
        return response.body;
      }));
  }

  // Load Version
  getVersionInfo(filter: any, sortColumn: string, sortOrder: string, pageNumber: number, pageSize: number): Observable<any> {
    return this._httpClient.post<any>("/VersionInfo/GetVersionInfo", { filter, sortColumn, sortOrder, pageNumber, pageSize }, { responseType: "json", observe: "response" }).pipe(map(response => {
      return response.body;
    }));
  }

  // Get Last Version
  getLastVersion(): Observable<any> {
    return this._httpClient.post("/VersionInfo/GetLastVersion", null, { responseType: "text" });
  }

  // Check Version Info
  checkVersionInfo(version: string, info: string, version_info_id: number): Observable<any> {
    return this._httpClient.post<any>(`/VersionInfo/CekVersionInfo?version=${version}&info=${info}&record_id=${version_info_id}`, null, { responseType: "json", observe: "response" });
  }

  // Save Version Info
  addVersionInfo(param: VersionInfoViewModel) {
    return this._httpClient.post<any>("/VersionInfo/Simpan", param, { responseType: "json", observe: "response" }).pipe(map(response => {
      return response.body;
    }));
  }

  // Delete Version Info
  deleteVersionInfo(version_info_id: number): Observable<any> {
    return this._httpClient.post(`/VersionINfo/Hapus?version_info_id=${version_info_id}`, null, { responseType: "json" });
  }
}
