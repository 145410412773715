import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private data = null;
  pageTypeModal: string;
  approve: boolean
  constructor() { }

  setOption(value) {
    this.data = value;
  }
  getOption() {
    return this.data;
  }
}
