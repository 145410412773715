import { Routes } from "@angular/router";

export const laporanRoutes: Routes = [
  {
      path: 'laporan-jamsostek-supir',
      loadChildren: () =>
          import(
              '@laporan/laporan-jamsostek-supir/laporan-jamsostek-supir.module'
          ).then((m) => m.LaporanJamsostekSupirModule)
  },
  {
      path: 'laporan-harian-operasional',
      loadChildren: () =>
          import(
              '@laporan/laporan-harian-operasional/laporan-harian-operasional.module'
          ).then((m) => m.LaporanHarianOperasionalModule),
  },
  {
      path: 'laporan-harian-operasional-trado',
      loadChildren: () =>
          import(
              '@laporan/laporan-harian-operasional-trado/laporan-harian-operasional-trado.module'
          ).then((m) => m.LaporanHarianOperasionalTradoModule),
  },
  {
      path: 'laporan-harian-operasional-trado-pi',
      loadChildren: () =>
          import(
              '@laporan/laporan-harian-operasional-trado-pi/laporan-harian-operasional-trado-pi.module'
          ).then((m) => m.LaporanHarianOperasionalTradoPiModule),
  },
  {
      path: 'laporan-detail-outstanding-kontrak',
      loadChildren: () =>
          import(
              '@laporan/laporan-detail-outstanding-kontrak/laporan-detail-outstanding-kontrak.module'
          ).then((m) => m.LaporanDetailOutstandingKontrakModule),
  },
  {
      path: 'laporan-detail-outstanding-pi',
      loadChildren: () =>
          import(
              '@laporan/laporan-detail-outstanding-pi/laporan-detail-outstanding-pi.module'
          ).then((m) => m.LaporanDetailOutstandingPIModule),
  },
  {
      path: 'laporan-permintaan-angkutan',
      loadChildren: () =>
          import(
              '@laporan/laporan-permintaan-angkutan/laporan-permintaan-angkutan.module'
          ).then((m) => m.LaporanPermintaanAngkutanModule),
  },
  {
      path: 'laporan-suj-yang-tidak-ada-spb',
      loadChildren: () =>
          import(
              '@laporan/laporan-suj-yang-tidak-ada-spb/laporan-suj-yang-tidak-ada-spb.module'
          ).then((m) => m.LaporanSujYangTidakAdaSpbModule),
  },
  {
      path: 'laporan-suj-trado-yang-tidak-ada-spb',
      loadChildren: () =>
          import(
              '@laporan/laporan-suj-trado-yang-tidak-ada-spb/laporan-suj-trado-yang-tidak-ada-spb.module'
          ).then((m) => m.LaporanSujTradoYangTidakAdaSpbModule),
  },
  {
      path: 'laporan-voucher-harian-dan-bulanan',
      loadChildren: () =>
          import(
              '@laporan/laporan-voucher-harian-dan-bulanan/laporan-voucher-harian-dan-bulanan.module'
          ).then((m) => m.LaporanVoucherHarianDanBulananModule),
  },
  {
      path: 'laporan-perincian-kontrak',
      loadChildren: () =>
          import(
              '@laporan/laporan-perincian-kontrak/laporan-perincian-kontrak.module'
          ).then((m) => m.LaporanPerincianKontrakModule),
  },
  {
      path: 'laporan-perincian-kontrak-trado',
      loadChildren: () =>
          import(
              '@laporan/laporan-perincian-kontrak-trado/laporan-perincian-kontrak-trado.module'
          ).then((m) => m.LaporanPerincianKontrakTradoModule),
  },
  {
      path: 'laporan-suj-retur-tidak-ada-revisi',
      loadChildren: () => 
          import(
              '@laporan/laporan-suj-retur-tidak-ada-revisi/laporan-suj-retur-tidak-ada-revisi.module'
          ).then((m) => m.LaporanSujReturTidakAdaRevisiModule)
  },
  {
      path: 'laporan-selisih-tonase-per-supir',
      loadChildren: () =>
          import(
              '@laporan/laporan-selisih-tonase-per-supir/laporan-selisih-tonase-per-supir.module'
          ).then((m) => m.LaporanSelisihTonasePerSupirModule),
  },
  {
      path: 'laporan-selisih-tonase-per-kontrak',
      loadChildren: () =>
          import(
              '@laporan/laporan-selisih-tonase-per-kontrak/laporan-selisih-tonase-per-kontrak.module'
          ).then((m) => m.LaporanSelisihTonasePerKontrakModule),
  },
  {
      path: 'laporan-pencapaian-armada-harian',
      loadChildren: () =>
          import(
              '@laporan/laporan-pencapaian-armada/laporan-pencapaian-armada-harian/laporan-pencapaian-armada-harian.module'
          ).then((m) => m.LaporanPencapaianArmadaHarianModule),
  },
  {
      path: 'laporan-pencapaian-armada-mingguan',
      loadChildren: () =>
          import(
              '@laporan/laporan-pencapaian-armada/laporan-pencapaian-armada-mingguan/laporan-pencapaian-armada-mingguan.module'
          ).then((m) => m.LaporanPencapaianArmadaMingguanModule),
  },
  {
      path: 'laporan-pencapaian-armada-tahunan-tonase',
      loadChildren: () =>
          import(
              '@laporan/laporan-pencapaian-armada-tahunan-tonase/laporan-pencapaian-armada-tahunan-tonase.module'
          ).then((m) => m.LaporanPencapaianArmadaTahunanTonaseModule),
  },
  {
      path: 'laporan-pencapaian-armada-tahunan-poin',
      loadChildren: () =>
          import(
              '@laporan/laporan-pencapaian-armada-tahunan-poin/laporan-pencapaian-armada-tahunan-poin.module'
          ).then((m) => m.LaporanPencapaianArmadaTahunanPoinModule),
  },
  {
      path: 'laporan-insentif-poin',
      loadChildren: () =>
          import(
              '@laporan/laporan-insentif-poin/laporan-insentif-poin.module'
          ).then((m) => m.LaporanInsentifPoinModule),
  },
  {
      path: 'laporan-insentif-trip',
      loadChildren: () =>
          import(
              '@laporan/laporan-insentif-trip/laporan-insentif-trip.module'
          ).then((m) => m.LaporanInsentifTripModule),
  },
  {
      path: 'laporan-kekuatan-armada',
      loadChildren: () =>
          import(
              '@laporan/laporan-kekuatan-armada/laporan-kekuatan-armada.module'
          ).then((m) => m.LaporanKekuatanArmadaModule),
  },
  {
      path: 'laporan-efektifitas-armada',
      loadChildren: () =>
          import(
              '@laporan/laporan-efektifitas-armada/laporan-efektifitas-armada.module'
          ).then((m) => m.LaporanEfektifitasArmadaModule),
  },
  {
      path: 'laporan-keterlambatan-armada',
      loadChildren: () =>
          import(
              '@laporan/laporan-keterlambatan-armada/laporan-keterlambatan-armada.module'
          ).then((m) => m.LaporanKeterlambatanArmadaModule),
  },
  {
      path: 'laporan-persentase-analisa-masalah-armada',
      loadChildren: () =>
          import(
              '@laporan/laporan-persentase-analisa-masalah-armada/laporan-persentase-analisa-masalah-armada.module'
          ).then(
              (m) => m.LaporanPersentaseAnalisaMasalahArmadaModule
          ),
  },
  {
      path: 'laporan-persentase-armada-gantung',
      loadChildren: () =>
          import(
              '@laporan/laporan-persentase-armada-gantung/laporan-persentase-armada-gantung.module'
          ).then((m) => m.LaporanPersentaseArmadaGantungModule),
  },
  {
      path: 'laporan-rekapan-status-armada',
      loadChildren: () =>
          import(
              '@laporan/laporan-rekapan-status-armada/laporan-rekapan-status-armada.module'
          ).then((m) => m.LaporanRekapanStatusArmadaModule),
  },
  {
      path: 'laporan-rekapan-status-armada-tambah',
      loadChildren: () =>
          import(
              '@laporan/laporan-rekapan-status-armada-tambah/laporan-rekapan-status-armada-tambah.module'
          ).then((m) => m.LaporanRekapanStatusArmadaTambahModule),
  },
  {
      path: 'laporan-rekapan-outstanding-kontrak',
      loadChildren: () =>
          import(
              '@laporan/laporan-rekapan-outstanding-kontrak/laporan-rekapan-outstanding-kontrak.module'
          ).then((m) => m.LaporanRekapanOutstandingKontrakModule),
  },
  {
      path: 'laporan-rekap-do-harian',
      loadChildren: () =>
          import(
              '@laporan/laporan-rekap-do-harian/laporan-rekap-do-harian.module'
          ).then((m) => m.LaporanRekapanDoHarianModule),
  },
  {
      path: 'laporan-rekap-do-mingguan',
      loadChildren: () =>
          import(
              '@laporan/laporan-rekap-do-mingguan/laporan-rekap-do-mingguan.module'
          ).then((m) => m.LaporanRekapanDoMingguanModule),
  },
  {
      path: 'laporan-rekap-do-bulanan',
      loadChildren: () =>
          import(
              '@laporan/laporan-rekap-do-bulanan/laporan-rekap-do-bulanan.module'
          ).then((m) => m.LaporanRekapDoBulananModule),
  },
  {
      path: 'laporan-rekap-target-kekuatan-armada',
      loadChildren: () =>
          import(
              '@laporan/laporan-rekap-target-kekuatan-armada/laporan-rekap-target-kekuatan-armada.module'
          ).then((m) => m.LaporanRekapTargetKekuatanArmadaModule),
  },
  {
      path: 'laporan-riwayat-uang-jalan',
      loadChildren: () =>
          import(
              '@laporan/laporan-riwayat-uang-jalan/laporan-riwayat-uang-jalan.module'
          ).then((m) => m.LaporanRiwayatUangJalanModule),
  },
  {
      path: 'laporan-list-harian-bulanan',
      loadChildren: () =>
          import(
              '@laporan/laporan-list-harian-bulanan/laporan-list-harian-bulanan.module'
          ).then((m) => m.LaporanListHarianBulananModule),
  },
  {
      path: 'laporan-piutang-supir',
      loadChildren: () =>
          import(
              '@laporan/laporan-piutang-supir/laporan-piutang-supir.module'
          ).then((m) => m.LaporanPiutangSupirModule),
  },
  {
      path: 'laporan-suj-supir-cadangan',
      loadChildren: () =>
          import(
              '@laporan/laporan-suj-supir-cadangan/laporan-suj-supir-cadangan.module'
          ).then((m) => m.LaporanSujSupirCadanganModule),
  },
  {
      path: 'laporan-klaim-supir',
      loadChildren: () =>
          import(
              '@laporan/laporan-klaim-supir/laporan-klaim-supir/laporan-klaim-supir.module'
          ).then((m) => m.LaporanKlaimSupirModule),
  },
  {
      path: 'laporan-klaim-supir-first-type',
      loadChildren: () =>
          import(
              '@laporan/laporan-klaim-supir/laporan-klaim-supir-first-type/laporan-klaim-supir-first-type.module'
          ).then((m) => m.LaporanKlaimSupirFirstTypeModule),
  },
  {
      path: 'laporan-klaim-supir-second-type',
      loadChildren: () =>
          import(
              '@laporan/laporan-klaim-supir/laporan-klaim-supir-second-type/laporan-klaim-supir-second-type.module'
          ).then((m) => m.LaporanKlaimSupirSecondTypeModule),
  },
  {
      path: 'laporan-aktivitas-supir',
      loadChildren: () =>
          import(
              '@laporan/laporan-aktivitas-supir/laporan-aktivitas-supir.module'
          ).then((m) => m.LaporanAktivitasSupirModule),
  },
  {
      path: 'gps-report',
      loadChildren: () =>
          import(
              '@laporan/laporan-gps-map/gps-report/gps-report.module'
          ).then((m) => m.GpsReportModule),
  },
  {
      path: 'park-report',
      loadChildren: () =>
          import(
              '@laporan/laporan-gps-map/park-report/park-report.module'
          ).then((m) => m.ParkReportModule),
  },
  {
      path: 'mileage-report',
      loadChildren: () =>
          import(
              '@laporan/laporan-gps-map/mileage-report/mileage-report.module'
          ).then((m) => m.MileageReportModule),
  },
  {
      path: 'running-statement',
      loadChildren: () =>
          import(
              '@laporan/laporan-gps-map/running-statement/running-statement.module'
          ).then((m) => m.RunningStatementModule),
  },
  {
      path: 'loading-report',
      loadChildren: () =>
          import(
              '@laporan/laporan-gps-map/loading-report/loading-report.module'
          ).then((m) => m.LoadingReportModule),
  },
  {
    path: 'laporan-aktivitas-armada-per-jam',
    loadChildren: () => 
      import(
        '@laporan/laporan-aktivitas-armada-per-jam/laporan-aktivitas-armada-per-jam.module'
      ).then((m) =>  m.LaporanAktivitasArmadaPerJamModule)
  },
  {
    path: 'laporan-poin-armada-gantung',
    loadChildren: () => 
      import(
        '@laporan/laporan-poin-armada-gantung/laporan-poin-armada-gantung.module'
      ).then((m) => m.LaporanPoinArmadaGantungModule)
  },
  {
    path: 'laporan-persediaan-kontainer',
    loadChildren: () =>
      import(
        '@laporan/laporan-persediaan-kontainer/laporan-persediaan-kontainer.module'
      ).then((m) => m.LaporanPersediaanKontainerModule)
  }

]