import { Routes } from '@angular/router';
import { Error404Component } from 'app/main/errors/404/error-404.component';
import { dashboardRoutes } from './dashboard.routes';
import { homeRoutes } from './home.routes';
import { laporanRoutes } from './laporan.routes';
import { masterDataRoutes } from './master-data.routes';
import { pengaturanRoutes } from './pengaturan.routes';
import { transaksiRoutes } from './transaksi.routes';

export const appRoutes: Routes = [
  {
      path: '',
      redirectTo: 'home/login',
      pathMatch: 'full',
  },
  {
      path: 'home',
      // loadChildren: () => import('@home/home.module').then(m => m.HomeModule)
      children: homeRoutes,
  },
  {
      path: 'dashboard',
      children: dashboardRoutes,
  },
  {
      path: 'master-data',
      children: masterDataRoutes,
  },
  {
      path: 'transaksi',
      children: transaksiRoutes,
  },
  {
      path: 'laporan',
      children: laporanRoutes,
  },
  {
      path: 'pengaturan',
      children: pengaturanRoutes,
  },
  {
    path: 'transfer',
    loadChildren: () => import('../main/transfer/transfer.module').then((m) => m.TransferModule)
  },
  {
      path: 'errors',
      loadChildren: () =>
          import('../main/errors/errors.module').then((m) => m.ErrorsModule),
  },
  { path: '**', pathMatch: 'full', component: Error404Component },
]