import { Routes } from "@angular/router";

export const masterDataRoutes: Routes = [
  {
      path: 'armada-data',
      loadChildren: () =>
          import(
              '@master-data/armada/armada-data/armada-data.module'
          ).then((m) => m.ArmadaDataModule),
  },
  {
      path: 'armada-trip',
      loadChildren: () =>
          import(
              '@master-data/armada-trip/armada-trip-data/armada-trip-data.module'
          ).then((m) => m.ArmadaTripDataModule),
  },
  {
      path: 'bank',
      loadChildren: () =>
          import('@master-data/bank/bank/bank-data.module').then(
              (m) => m.BankDataModule
          ),
  },
  {
      path: 'biaya-ekspedisi',
      loadChildren: () =>
          import(
              '@master-data/EMKL/biaya-ekspedisi/biaya-ekspedisi.module'
          ).then(m => m.BiayaEkspedisiModule),
  },
  {
      path: 'hold-kapal-principal',
      loadChildren: () =>
          import(
              '@master-data/EMKL/hold-kapal-principal/hold-kapal-principal.module'
          ).then(m => m.HoldKapalPrincipalModule),
  },
  {
      path: 'budget-tahunan-data',
      loadChildren: () =>
          import(
              '@master-data/budget-tahunan/budget-tahunan-data/budget-tahunan-data.module'
          ).then((m) => m.BudgetTahunanDataModule),
  },
  {
      path: 'budget-tahunan-tambah',
      loadChildren: () =>
          import(
              '@master-data/budget-tahunan/budget-tahunan-tambah/budget-tahunan-tambah.module'
          ).then((m) => m.BudgetTahunanTambahModule),
  },
  {
      path: 'customers',
      loadChildren: () =>
          import(
              '@master-data/customer/customer-data/customer-data.module'
          ).then((m) => m.CustomerDataModule),
  },
  {
      path: 'customers',
      loadChildren: () =>
          import(
              '@master-data/customer/customer-tambah/customer-tambah.module'
          ).then((m) => m.CustomerTambahModule),
  },
  {
      path: 'ekspedisi',
      loadChildren: () =>
          import(
              '@master-data/EMKL/ekspedisi-data/ekspedisi.module'
          ).then((m) => m.EkspedisiModule),
  },
  {
      path: 'ekspedisi-tambah',
      loadChildren: () =>
          import(
              '@master-data/EMKL/ekspedisi-tambah/ekspedisi-tambah.module'
          ).then((m) => m.EkspedisiTambahModule),
  },
  {
      path: 'gandengan-data',
      loadChildren: () =>
          import(
              '@master-data/gandengan/gandengan-data/gandengan-data.module'
          ).then((m) => m.GandenganDataModule),
  },
  {
      path: 'gandengan-riwayat-data',
      loadChildren: () =>
          import(
              '@master-data/gandengan/gandengan-riwayat-data/gandengan-riwayat-data.module'
          ).then((m) => m.GandenganRiwayatDataModule),
  },
  {
      path: 'hadiah-voucher-data',
      loadChildren: () =>
          import(
              '@master-data/hadiah-voucher/hadiah-voucher-data/hadiah-voucher-data.module'
          ).then((m) => m.HadiahVoucherDataModule),
  },
  {
      path: 'jenis-bbm',
      loadChildren: () => 
          import(
            `@master-data/jenis-bbm/jenis-bbm.module`
          ).then((m) => m.JenisBbmModule)
  },
  {
      path: 'harga-bbm-data',
      loadChildren: () =>
          import(
              '@master-data/harga-bbm/harga-bbm-data/harga-bbm-data.module'
          ).then((m) => m.HargaBbmDataModule),
  },
  {
      path: 'insentif-poin',
      loadChildren: () =>
          import(
              '@master-data/insentif-poin/insentif-poin-data/insentif-poin-data.module'
          ).then((m) => m.InsentifPoinDataModule),
  },
  {
      path: 'merk-armada',
      loadChildren: () => 
          import(
              '@master-data/merk-armada/merk-data/merk-data.module'
          ).then((m) => m.MerkDataModule)
  },
  {
      path: 'jenis-armada-data',
      loadChildren: () =>
          import(
              '@master-data/jenis-armada/jenis-armada-data/jenis-armada-data.module'
          ).then((m) => m.JenisArmadaDataModule),
  },
  {
      path: 'jenis-kendaraan-inventaris',
      loadChildren: () => 
        import(
          '@master-data/jenis-kendaraan-inventaris/jenis-kendaraan-inventaris.module'
        ).then((m) => m.JenisKendaraanInventarisModule)
  },
  {
      path: 'jenis-biaya-ekspedisi',
      loadChildren: () =>
          import(
              '@master-data/EMKL/jenis-biaya-ekspedisi/jenis-biaya-ekspedisi.module'
          ).then((m) => m.JenisBiayaEkspedisiModule),
  },
  {
      path: 'jenis-container',
      loadChildren: () =>
          import(
              '@master-data/jenis-container/jenis-container-data/jenis-container-data.module'
          ).then((m) => m.JenisContainerModule),
  },
  {
      path: 'jenis-uang-tambahan',
      loadChildren: () =>
          import(
              '@master-data/jenis-uang-tambahan/jenis-uang-tambahan-data/jenis-uang-tambahan-data.module'
          ).then((m) => m.JenisUangTambahanDataModule),
  },
  {
      path: 'lokasi-data',
      loadChildren: () =>
          import(
              '@master-data/lokasi/lokasi-data/lokasi-data.module'
          ).then((m) => m.LokasiDataModule),
  },
  {
      path: 'lokasi-tambah',
      loadChildren: () =>
          import(
              '@master-data/lokasi/lokasi-tambah/lokasi-tambah.module'
          ).then((m) => m.LokasiTambahModule),
  },
  {
      path: 'kendaraan-inventaris',
      loadChildren: () =>
        import(
            '@master-data/kendaraan-inventaris/kendaraan-inventaris.module'
        ).then((m) => m.KendaraanInventarisModule),
  },
  {
      path: 'keterangan-armada-gantung',
      loadChildren: () =>
          import(
              '@master-data/keterangan-armada-gantung/keterangan-armada-gantung-data/keterangan-armada-gantung-data.module'
          ).then((m) => m.KeteranganArmadaGantungDataModule),
  },
  {
      path: 'komoditi-data',
      loadChildren: () =>
          import(
              '@master-data/komoditi/komoditi-data/komoditi-data.module'
          ).then((m) => m.KomoditiDataModule),
  },
  {
      path: 'komoditi-tambah',
      loadChildren: () =>
          import(
              '@master-data/komoditi/komoditi-tambah/komoditi-tambah.module'
          ).then((m) => m.KomoditiTambahModule),
  },
  {
      path: 'jenis-ongkos-angkut-trado',
      loadChildren: () => 
          import(
              '@master-data/jenis-ongkos-angkut-trado/jenis-ongkos-angkut-trado.module'
          ).then((m) => m.JenisOngkosAngkutTradoModule)
  },
  {
      path: 'ongkos-angkut-data',
      loadChildren: () =>
          import(
              '@master-data/ongkos-angkut/ongkos-angkut-data/ongkos-angkut-data.module'
          ).then((m) => m.OngkosAngkutDataModule),
  },
  {
      path: 'ongkos-angkut-tambah',
      loadChildren: () =>
          import(
              '@master-data/ongkos-angkut/ongkos-angkut-tambah/ongkos-angkut-tambah.module'
          ).then((m) => m.OngkosAngkutTambahModule),
  },
  {
      path: 'ongkos-angkut-sub',
      loadChildren: () =>
          import(
              '@master-data/ongkos-angkut-sub/ongkos-angkut-sub-data/ongkos-angkut-sub-data.module'
          ).then((m) => m.OngkosAngkutSubDataModule),
  },
  {
      path: 'ongkos-angkut-sub-trado-data',
      loadChildren: () =>
          import(
              '@master-data/ongkos-angkut-sub-trado/ongkos-angkut-sub-trado-data/ongkos-angkut-sub-trado-data.module'
          ).then((m) => m.OngkosAngkutSubTradoDataModule),
  },
  {
      path: 'ongkos-angkut-trado-data',
      loadChildren: () =>
          import(
              '@master-data/ongkos-angkut-trado/ongkos-angkut-trado-data/ongkos-angkut-trado-data.module'
          ).then((m) => m.OngkosAngkutTradoDataModule),
  },
  {
      path: 'rfid-data',
      loadChildren: () =>
          import('@master-data/rfid/rfid-data/rfid-data.module').then(
              (m) => m.RfidDataModule
          ),
  },
  {
      path: 'satuan-data',
      loadChildren: () =>
          import(
              '@master-data/satuan/satuan-data/satuan-data.module'
          ).then((m) => m.SatuanDataModule),
  },
  {
      path: 'sub-pengangkutan-data',
      loadChildren: () =>
          import(
              '@master-data/sub-pengangkutan/sub-pengangkutan-data/sub-pengangkutan-data.module'
          ).then((m) => m.SubPengangkutanDataModule),
  },
  {
      path: 'sub-pengangkutan',
      loadChildren: () =>
          import(
              '@master-data/sub-pengangkutan/sub-pengangkutan-tambah/sub-pengangkutan-tambah.module'
          ).then((m) => m.SubPengangkutanTambahModule),
  },
  {
      path: 'supir-data',
      loadChildren: () =>
          import(
              '@master-data/supir/supir-data/supir-data.module'
          ).then((m) => m.SupirDataModule),
  },
  {
      path: 'supir-tambah',
      loadChildren: () =>
          import(
              '@master-data/supir/supir-tambah/supir-tambah.module'
          ).then((m) => m.SupirTambahModule),
  },
  {
      path: 'realisasi-poin-data',
      loadChildren: () =>
          import(
              '@master-data/target-poin/realisasi-poin-data/realisasi-poin-data.module'
          ).then((m) => m.RealisasiPoinDataModule),
  },
  {
      path: 'target-poin-data',
      loadChildren: () =>
          import(
              '@master-data/target-poin/target-poin-data/target-poin-data.module'
          ).then((m) => m.TargetPoinDataModule),
  },
  {
      path: 'target-poin-tambah',
      loadChildren: () =>
          import(
              '@master-data/target-poin/target-poin-tambah/target-poin-tambah.module'
          ).then((m) => m.TargetPoinTambahModule),
  },
  {
      path: 'target-produksi',
      children: [
          {
              path: '',
              loadChildren: () =>
                  import(
                      '@master-data/target-produksi/target-produksi-data/target-produksi-data.module'
                  ).then((m) => m.TargetProduksiDataModule),
          },
          {
              path: 'new',
              loadChildren: () =>
                  import(
                      '@master-data/target-produksi/target-produksi-tambah/target-produksi-tambah.module'
                  ).then((m) => m.TargetProduksiTambahModule),
          },
          {
              path: 'edit',
              loadChildren: () =>
                  import(
                      '@master-data/target-produksi/target-produksi-tambah/target-produksi-tambah.module'
                  ).then((m) => m.TargetProduksiTambahModule),
          },
      ],
  },
  {
      path: 'uang-gantung-data',
      loadChildren: () =>
          import(
              '@master-data/uang-gantung/uang-gantung-data/uang-gantung-data.module'
          ).then((m) => m.UangGantungDataModule),
  },
  {
      path: 'uang-jalan',
      children: [
          {
              path: '',
              loadChildren: () =>
                  import(
                      '@master-data/uang-jalan/uang-jalan-data/uang-jalan-data.module'
                  ).then((m) => m.UangJalanDataModule),
          },
          {
              path: 'tambah',
              loadChildren: () =>
                  import(
                      '@master-data/uang-jalan/uang-jalan-tambah/uang-jalan-tambah.module'
                  ).then((m) => m.UangJalanTambahModule),
          },
          {
              path: 'edit',
              loadChildren: () =>
                  import(
                      '@master-data/uang-jalan/uang-jalan-tambah/uang-jalan-tambah.module'
                  ).then((m) => m.UangJalanTambahModule),
          },
      ],
  },
  {
      path: 'uang-jalan-langsir',
      children: [
          {
              path: '',
              loadChildren: () =>
                  import(
                      '@master-data/uang-jalan-langsir/uang-jalan-langsir-data/uang-jalan-langsir-data.module'
                  ).then((m) => m.UangJalanLangsirDataModule),
          },
          {
              path: 'tambah',
              loadChildren: () =>
                  import(
                      '@master-data/uang-jalan-langsir/uang-jalan-langsir-tambah/uang-jalan-langsir-tambah.module'
                  ).then((m) => m.UangJalanLangsirTambahModule),
          },
          {
              path: 'edit',
              loadChildren: () =>
                  import(
                      '@master-data/uang-jalan-langsir/uang-jalan-langsir-tambah/uang-jalan-langsir-tambah.module'
                  ).then((m) => m.UangJalanLangsirTambahModule),
          },
      ],
  },
  {
      path: 'uang-jalan-trado',
      children: [
          {
              path: '',
              loadChildren: () =>
                  import(
                      '@master-data/uang-jalan-trado/uang-jalan-trado-data/uang-jalan-trado-data.module'
                  ).then((m) => m.UangJalanTradoDataModule),
          },
          {
              path: 'tambah',
              loadChildren: () =>
                  import(
                      '@master-data/uang-jalan-trado/uang-jalan-trado-tambah/uang-jalan-trado-tambah.module'
                  ).then((m) => m.UangJalanTradoTambahModule),
          },
          {
              path: 'edit',
              loadChildren: () =>
                  import(
                      '@master-data/uang-jalan-trado/uang-jalan-trado-tambah/uang-jalan-trado-tambah.module'
                  ).then((m) => m.UangJalanTradoTambahModule),
          },
      ],
  },
  {
    path: 'kapal',
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            '@master-data/kapal/kapal-data/kapal-data.module'
          ).then((m) => m.KapalDataModule)
      },
    ]
  },
  {
    path: 'uang-jalan-mobilisasi',
    loadChildren: () => 
      import(
        '@master-data/uang-jalan-mobilisasi/uang-jalan-mobilisasi.module'
      ).then(m => m.UangJalanMobilisasiModule)
  },
  {
    path: 'uang-jalan-mobilisasi-trado',
    loadChildren: () => 
      import(
        '@master-data/uang-jalan-mobilisasi-trado/uang-jalan-mobilisasi-trado.module'
      ).then(m => m.UangJalanMobilisasiTradoModule)
  },
]