import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApproveService {

  constructor(private _httpClient: HttpClient) { }

  toQueryString = (obj: any) => "?".concat(Object.keys(obj).map(e => `${encodeURIComponent(e)}=${encodeURIComponent(obj[e])}`).join("&"));

  ApproveSUJ(status: number, alasan_tolak: string, comloc_id: string, data: string[]): Observable<any> {
    return this._httpClient.post<any>("/ArmadaBerangkat/NonTrado/SUJ/Approve?status=" + status
      + "&alasan_tolak=" + alasan_tolak + "&comloc_id=" + comloc_id, data, { responseType: "json", observe: "response" })
      .pipe(map(response => {
        return response.body;
      }));
  }
  ApproveSUT(status: number, alasan_tolak: string, comloc_id: string, data: string[]): Observable<any> { //204
    return this._httpClient.post<any>("/ArmadaBerangkat/NonTrado/SUT/Approve?status=" + status
      + "&alasan_tolak=" + alasan_tolak + "&comloc_id=" + comloc_id, data, { responseType: "json", observe: "response" })
      .pipe(map(response => {
        return response.body;
      }));
  }
  ApproveSUG(status: number, alasan_tolak: string, comloc_id: string, data: string[]): Observable<any> {
    return this._httpClient.post<any>("/SUG/NonTrado/Approve?status=" + status
      + "&alasan_tolak=" + alasan_tolak + "&comloc_id=" + comloc_id, data, { responseType: "json", observe: "response" })
      .pipe(map(response => {
        return response.body;
      }));
  }
  ApproveSUJTrado(status: number, alasan_tolak: string, comloc_id: string, data: string[]): Observable<any> {
    return this._httpClient.post<any>("/ArmadaBerangkat/Trado/SUJ/Approve?status=" + status
      + "&alasan_tolak=" + alasan_tolak + "&comloc_id=" + comloc_id, data, { responseType: "json", observe: "response" })
      .pipe(map(response => {
        return response.body;
      }));
  }
  ApproveSUTTrado(status: number, alasan_tolak: string, comloc_id: string, data: string[]): Observable<any> {
    return this._httpClient.post<any>("/ArmadaBerangkat/Trado/SUT/Approve?status=" + status
      + "&alasan_tolak=" + alasan_tolak + "&comloc_id=" + comloc_id, data, { responseType: "json", observe: "response" })
      .pipe(map(response => {
        return response.body;
      }));
  }
  ApproveSUGTrado(status: number, alasan_tolak: string, comloc_id: string, data: string[]): Observable<any> {
    return this._httpClient.post<any>("/SUG/Trado/Approve?status=" + status
      + "&alasan_tolak=" + alasan_tolak + "&comloc_id=" + comloc_id, data, { responseType: "json", observe: "response" })
      .pipe(map(response => {
        return response.body;
      }));
  } 
  ApproveSubDO(status: number, alasan_tolak: string, comloc_id: string, data: string[]): Observable<any> {
    return this._httpClient.post<any>("/SubDO/Approve?status=" + status
      + "&alasan_tolak=" + alasan_tolak + "&comloc_id=" + comloc_id, data, { responseType: "json", observe: "response" })
      .pipe(map(response => {
        return response.body;
      }));
  } 
  ApproveSubPI(status: number, alasan_tolak: string, comloc_id: string, data: string[]): Observable<any> {
    return this._httpClient.post<any>("/SubPI/Approve?status=" + status
      + "&alasan_tolak=" + alasan_tolak + "&comloc_id=" + comloc_id, data, { responseType: "json", observe: "response" })
      .pipe(map(response => {
        return response.body;
      }));
  } 
  ApprovePVB(status: number, alasan_tolak: string, comloc_id: string, data: string[]): Observable<any> {
    return this._httpClient.post<any>("/PemakaianVoucherBBM/Approve?status=" + status
      + "&alasan_tolak=" + alasan_tolak + "&comloc_id=" + comloc_id, data, { responseType: "json", observe: "response" })
      .pipe(map(response => {
        return response.body;
      }));
  }

  ApproveRSUJ(status: 1 | 2, alasan_tolak: string, comloc_id: string, ids: string[]): Observable<any> {
    return this._httpClient.post<any>(`/ArmadaBerangkat/NonTrado/RSUJ/Approve${this.toQueryString({status, alasan_tolak, comloc_id})}`, ids, { responseType: 'json', observe: 'response' }).pipe(map((resp) => {
      return resp.body;
    }))
  }

  ApproveRSUJT(status: 1 | 2, alasan_tolak: string, comloc_id: string, ids: string[]): Observable<any> {
    return this._httpClient.post<any>(`/ArmadaBerangkat/Trado/RSUJ/Approve${this.toQueryString({status, alasan_tolak, comloc_id})}`, ids, { responseType: 'json', observe: 'response' }).pipe(map((resp) => {
      return resp.body;
    }))
  }
}
