
import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';
import { AppSettingsService } from 'app/app.settings.service';
import { AppSettings } from 'app/app.settings';
import { AuthViewModel } from 'app/view-model/common/rfid/auth-view-model';
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable({
  providedIn: 'root'
})
export class RFIDService {
  public RFIDToken:string = "";
  public currentUserRFID:any = "" ;
  private httpClient: HttpClient;
  settings: AppSettings;
  baseUrl: string;
  RFIDauthenticate:AuthViewModel = new AuthViewModel();
  constructor(
    private httpBackend: HttpBackend,
    private jwtHelperService: JwtHelperService,
    private router: Router,
    private appSettingsService: AppSettingsService,
    private _matSnackBar: MatSnackBar, ) {
    this.appSettingsService.getSettings()
      .subscribe(settings =>
        this.settings = settings,
        () => null,
        () => {
          this.baseUrl = this.settings.baseUrlRFID;
        });
  }

  public authenticate(rfidViewModel: AuthViewModel): Observable<any> {
    this.httpClient = new HttpClient(this.httpBackend);
    return this.httpClient.post<any>(this.baseUrl + "/users/authenticate", (rfidViewModel), { responseType: "json", observe: "response" })
      .pipe(map(response => {
        localStorage.removeItem("currentUserRFID");
        localStorage.currentUserRFID = JSON.stringify(response.body);
        return response.body;
      }));
  }

  public simpan(paramRFID): Observable<any>{
    if (!this.currentUserRFID) {
      this.currentUserRFID = JSON.parse(localStorage.currentUserRFID);
    }
    this.httpClient = new HttpClient(this.httpBackend);
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8').set('observe','response').set('Authorization','Bearer ' + this.currentUserRFID.token);

    return this.httpClient.post<any>(this.baseUrl + "/voucher/simpan",paramRFID ,{headers})
    .pipe(map(response => {
      return response;
    }));
  }

  onLoginRFID(): void {
    try {
        //const data = this.userForm.getRawValue();
        this.authenticate(this.RFIDauthenticate).subscribe(resp => {
            
        //    console.log(resp); 
          }, error =>{
            console.error(error);
            this._matSnackBar.open('Oops!  RFID Gagal Login: Status error: ' + error.status
            +', Pesan ' + error.statusText + ', Silahkan Hubungi Administrator!', 'error', {
                verticalPosition: 'top',
                duration: 6000
            });
          });
        } 
        catch (e) {
        console.error(e);    
        this._matSnackBar.open('Oops! User Gagal Diupload: ' + e, 'error', {
            verticalPosition: 'top',
            duration: 5000
        });
    }
}

  public CheckStatusVoucher(paramRFID) {
      if (!this.currentUserRFID) {
        this.currentUserRFID = JSON.parse(localStorage.currentUserRFID);
      }
      this.httpClient = new HttpClient(this.httpBackend);
      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8').set('observe','response').set('Authorization','Bearer ' + this.currentUserRFID.token);

      return this.httpClient.get<any>(this.baseUrl + "/voucher/"+ paramRFID.no_kartu ,{headers})
      .pipe(map(response => {
        return response;
      }));
  }

  
}
  