<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="pacman">
    <p style="font-size: 20px; color: white">{{timeLeft}}...</p>
</ngx-spinner>
<div class="dialog-content-wrapper"  >
    <div class="header">
        <mat-toolbar class="mat-accent m-0">
            <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
                <span class="title dialog-title">{{ title }}</span>
                <button mat-icon-button (click)="onCancel('')" aria-label="Close dialog">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-toolbar-row>
        </mat-toolbar>
        <hr>
    </div>
    <div class="content-card">
        <div class="body" style="padding: 10px;text-align: center;">
            <mat-form-field appearance="outline"  floatLabel="always" fxFlex="100" fxFlex.xs="100"
            class="pr-12">
                <mat-label>Alasan</mat-label>
                <textarea matInput placeholder="" [(ngModel)]="messages" minlength="10" maxlength="200"></textarea>
                <mat-hint align="end">Minimal 10 karakter dari
                {{ messages.length ||
                        0
                }}/200</mat-hint>
              </mat-form-field>
        </div>
        <div class="footer" style="padding: 10px;">
            <div fxLayout="row end" fxLayoutAlign="end" fxFlex>
                <button   mat-raised-button color="accent" [disabled]="messages.length <= 10" (click)="onSave()"><span>SIMPAN</span></button>
                &nbsp;
                <button class="mat-raised-button" (click)="onCancel('')" aria-label="Close dialog"
                    xLayoutAlign="center right">
                    <span>BATAL</span>
                </button>
            </div>
        </div>
    </div>
</div>